export default {
  metaData: {
    title: 'Empleos en',
    description: {
      part1: 'Ofertas para trabajar en',
      part2: ', todas las vacantes de empleo en',
      part3: '- Empleos',
    },
  },
  anchorBar: {
    detalle: 'Sobre la empresa',
    calificaciones: 'Calificaciones',
    avisos: 'Empleos',
  },
  complementaryInfo: {
    anchorDescription: 'Ver todos los avisos',
    industryLabel: 'Industria:',
    locationLabel: 'Ubicación:',
    employeesCountLabel: 'Tamaño de la empresa:',
  },
  publications: {
    emptyStateDescription: 'En este momento, la empresa no tiene avisos publicados',
    vacanciesDescription: 'Hay <0>{{cant}}</0> empleos en {{nombreEmpresa}}',
    cvAnonimoDescription: 'Esta empresa recibirá tu {{cv}} anónimo sin foto, edad, género ni nacionalidad.',
  },
  qrModal: {
    success: {
      title: 'ya recibió tus datos',
      subTitle: 'Te invitamos a visitar el perfil de la empresa para conocer otras ofertas que pueden interesarte.',
      cta: 'Ir al perfil de la empresa',
    },
    failure: {
      title1: 'Activa tu cuenta para que',
      title2: 'reciba tus datos',
      subTitle: 'Te enviamos un email para que valides tu cuenta y puedas seguir postulándote. ',
      question: '¿No lo recibiste?',
      ctaEnable: 'Reenviarme el email',
      ctaDisable: 'Ya te reenviamos el mail de activación',
    },
  },
  seguirEmpresa: {
    seguir: 'Seguir',
    siguiendo: 'Siguiendo',
    dejarDeSeguir: 'Dejar de seguir',
  },
  gptw: {
    description: 'Somos referentes globales en clima y cultura organizacional',
    button: 'Ir al perfil de la empresa',
    defaultUrl: '',
  },
}
