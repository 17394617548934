import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const CardPlaceholder = props => {
  // obtenemos el ancho de LoaderContainer
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const widthWithMargin = wrapperWidth * 0.95
  const secondColX = widthWithMargin * 0.64
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={158}
        viewBox="0 0 100% 158"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <path d="M 0 0 h 6 v 158 H 0 z" />
        <rect x="24" y="50" rx="4" ry="4" width="68" height="44" />
        <rect x="24" y="16" rx="2" ry="2" width={widthWithMargin * 0.55} height="26" />
        <path d={`M ${widthWithMargin * 0.62} 16 h 1 v 126 h -1 z`} />
        <rect x={widthWithMargin * 0.9 + 32} y="16" rx="4" ry="4" width={widthWithMargin * 0.1} height="20" />
        <rect x="104" y="50" rx="2" ry="2" width={widthWithMargin * 0.5 - 100} height="18" />
        <rect x={secondColX + 28} y="16" rx="2" ry="2" width="174" height="20" />
        <rect x={secondColX} y="16" rx="4" ry="4" width="20" height="20" />
        <rect x={secondColX + 28} y="48" rx="2" ry="2" width="89" height="20" />
        <rect x={secondColX} y="48" rx="4" ry="4" width="20" height="20" />
        <rect x="104" y="78" rx="2" ry="2" width="157" height="16" />
        <rect x="24" y="103" rx="2" ry="2" width={widthWithMargin * 0.55} height="14" />
        <rect x="24" y="128" rx="2" ry="2" width={widthWithMargin * 0.5} height="14" />
      </ContentLoader>
    </LoaderContainer>
  )
}

export default CardPlaceholder
