import {
  UpdateDatosContacto,
  UPDATE_DATOS_CONTACTO,
  UPDATE_DATOS_CONTACTO_RESET,
  UpdateDatosContactoReset,
  UPDATE_IMAGE_PROFILE,
  UpdateImageProfile,
  AddDatosPersonales,
  ADD_DATOS_PERSONALES,
  ADD_DATOS_PERSONALES_RESET,
  AddDatosPersonalesReset,
  GET_APPLICANT,
  GET_APPLICANT_SUMMARY,
  GET_CURRICULUM,
  GetApplicantSummary,
  GetApplicant,
  GetCurriculum,
  RESET_APPLICANT,
  ResetApplicant,
  UPDATE_IMAGE_PROFILE_RESET,
  UpdateImageProfileReset,
  UpdateDocumento,
  UPDATE_DOCUMENTO,
  GET_VISUALIZACIONES_CV,
  GetVisualizacionesCV,
  GetDatosPersonales,
  GET_DATOS_PERSONALES,
  DELETE_IMAGE_PROFILE,
  DELETE_IMAGE_PROFILE_RESET,
  DeleteImageProfile,
  DeleteImageProfileReset,
  UPDATE_CANALES_DE_CONTACTO,
  UPDATE_CANALES_DE_CONTACTO_RESET,
  UpdateCanalesDeContacto,
  UpdateCanalesDeContactoReset,
} from '../../types/applicant/applicant'
import {
  UpdateCanalesDeContactoType,
  UpdateDatosContactoType,
  UpdateDatosPersonales,
  UpdateDocumentoPayload,
} from '../../types/applicant/commons'
import {
  UpdateDatosPersonalesOnbordingType,
  UPDATE_DATOS_PERSONALES_ONBORDING,
} from '../../types/applicant/curriculum/datos-personales-onbording'

export const updateDatosContacto = (datos: UpdateDatosContactoType): UpdateDatosContacto => {
  return {
    type: UPDATE_DATOS_CONTACTO,
    payload: datos,
  }
}

export const updateDatosContactoReset = (): UpdateDatosContactoReset => {
  return {
    type: UPDATE_DATOS_CONTACTO_RESET,
    payload: null,
  }
}

export const updateCanalesDeContacto = (datos: UpdateCanalesDeContactoType): UpdateCanalesDeContacto => {
  return {
    type: UPDATE_CANALES_DE_CONTACTO,
    payload: datos,
  }
}

export const updateCanalesDeContactoReset = (): UpdateCanalesDeContactoReset => {
  return {
    type: UPDATE_CANALES_DE_CONTACTO_RESET,
    payload: null,
  }
}

export const updateImageProfile = (datos: string): UpdateImageProfile => {
  return {
    type: UPDATE_IMAGE_PROFILE,
    payload: datos,
  }
}

export const updateImageProfileReset = (): UpdateImageProfileReset => {
  return {
    type: UPDATE_IMAGE_PROFILE_RESET,
    payload: null,
  }
}

export const deleteImageProfile = (): DeleteImageProfile => {
  return {
    type: DELETE_IMAGE_PROFILE,
    payload: null,
  }
}

export const deleteImageProfileReset = (): DeleteImageProfileReset => {
  return {
    type: DELETE_IMAGE_PROFILE_RESET,
    payload: null,
  }
}

export const addDatosPersonales = (datos: UpdateDatosPersonales): AddDatosPersonales => {
  return {
    type: ADD_DATOS_PERSONALES,
    payload: datos,
  }
}

export const updateDocumento = (datos: UpdateDocumentoPayload): UpdateDocumento => {
  return {
    type: UPDATE_DOCUMENTO,
    payload: datos,
  }
}

export const addDatosPersonalesReset = (): AddDatosPersonalesReset => {
  return {
    type: ADD_DATOS_PERSONALES_RESET,
    payload: null,
  }
}

export const getApplicantSummary = (): GetApplicantSummary => {
  return {
    type: GET_APPLICANT_SUMMARY,
    payload: null,
  }
}

export const getDatosPersonales = (): GetDatosPersonales => {
  return {
    type: GET_DATOS_PERSONALES,
    payload: null,
  }
}

export const getApplicant = (): GetApplicant => {
  return {
    type: GET_APPLICANT,
    payload: null,
  }
}

export const getCurriculum = (): GetCurriculum => {
  return {
    type: GET_CURRICULUM,
    payload: null,
  }
}

export const resetApplicant = (): ResetApplicant => {
  return {
    type: RESET_APPLICANT,
    payload: null,
  }
}

export const UpdateDatosPersonalesOnbording = (datos: UpdateDatosPersonalesOnbordingType) => {
  return {
    type: UPDATE_DATOS_PERSONALES_ONBORDING,
    payload: datos,
  }
}

export const getVisualizacionesCVAction = (): GetVisualizacionesCV => {
  return {
    type: GET_VISUALIZACIONES_CV,
  }
}
