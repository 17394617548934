import styled from 'styled-components'

export const DataInfo = styled('span')`
  margin: 0px;
  &,
  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const DataInfoContainer = styled('div')`
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  scroll-behavior: smooth;

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    flex: 0 0 25%;
    max-width: 25%;
  }`}
`
