export default {
  datosContacto: {
    form: {
      provinciaTitle: 'Estado',
      localidadTitle: 'Ciudad',
    },
  },
  legales:
    'es responsable por la Base de Datos. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos personalmente o por correo postal a la dirección Av. Baja California 245, Ofi. 501-M, Hipódromo De La Condesa, Alcaldía Cuauhtémoc, C.P. 06170, Ciudad de México, México.',
  onboarding: {
    steps: {
      finalizar: {
        title: '¡Listo! Ya puedes empezar a postularte',
      },
    },
  },
}
