import { merge } from 'lodash'
import all from '../all'
import ctaDownloadApp from './cta-download-app'
import userPremium from './user-premium'
import pdaPago from './pda-pago'
import curriculum from './curriculum'
import marketplace from './marketplace'
import products from './products'
import saveJobPosting from './save-job-posting'
import navbarMobile from './navbar-mobile'
import fichaAviso from './ficha-aviso'
import sortEmpresas from './sortEmpresas'
import avisosFake from './avisos-fake'

const zjar = {
  ...all,
  ctaDownloadApp,
  userPremium: { ...all.userPremium, ...userPremium },
  pdaPago,
  curriculum: merge({}, all.curriculum, curriculum),
  marketplace: merge({}, all.marketplace, marketplace),
  products: merge({}, all.products, products),
  saveJobPosting: merge({}, all.saveJobPosting, saveJobPosting),
  navbarMobile: merge({}, all.navbarMobile, navbarMobile),
  fichaAviso: merge({}, all.fichaAviso, fichaAviso),
  sortEmpresas: merge({}, all.sortEmpresas, sortEmpresas),
  avisosFake: merge({}, all.avisosFake, avisosFake),
}

export default zjar
