import styled from 'styled-components'

export const LastDataContainer = styled('div')`
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  scroll-behavior: smooth;

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }`}
`

export const LastInfoCol = styled<{ isAvisoZonaJobs: boolean; aptoDiscapacitado: boolean }>('div')`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ mobileFormat, theme, crossSite, aptoDiscapacitado }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    flex-direction: column;
    align-items: flex-end;
    ${
      aptoDiscapacitado || crossSite
        ? 'justify-content: space-between'
        : !aptoDiscapacitado
        ? 'justify-content: flex-start'
        : 'justify-content: flex-end'
    };
  }`}
`

export const DisabilityContainer = styled<{ hasHighlight: boolean; crossSite: boolean }>('div')`
  display: flex;
  align-items: center;
  width: 100%;
  ${({ crossSite }) => crossSite && 'order: 1'}
  justify-content: flex-end;
  position: absolute;
  bottom: 0;

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    margin-bottom: 0;
  }`}
`

export const InterPageLogo = styled<{ aptoDiscapacitado: boolean }>('img')`
  max-width: 71px;
  height: 20px;
  display: inline-block;
  order: 1;

  ${({ mobileFormat, theme, aptoDiscapacitado }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    order: 0;
    ${!aptoDiscapacitado ? 'margin-bottom: 0px' : 'margin-bottom: 14px'};
  }`}
`
