import { Request, Get } from '@navent-jobs/config'
import { isEmpty } from 'lodash'

export class ErrorApplicant extends Error {}

class RutValidation {
  public async ValidateRut(rutUser: string) {
    const request = new Request()
    request.path = `api/ajustes/cuenta/validate-rut/${rutUser}`
    request.method = Get
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorApplicant(`Rut inválido, sin respuesta`)
        }

        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorApplicant(`Rut inválido, no encontrado`)
        }
      })
  }
}
const rutValidation = new RutValidation()
export default rutValidation
