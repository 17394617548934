export const mensajes = {
  placeholder: {
    input: 'Escribí tu mensaje',
  },
  emptyState: {
    mainPageTitle: 'Todavía no tenés ningún mensaje',
    mainPageSubTitle: 'Cuando una empresa te contacte, los mensajes aparecerán en esta pantalla.',
    channel: {
      title: 'Seleccioná una conversación para ver los mensajes o enviar uno nuevo',
    },
  },
  error: 'Hubo un error, volvé a intentarlo.',
}
