import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

import { CorazonContainer, Container, ActionContainer } from './mixins'

import CorazonAnimado from '../../../corazon-animado'

export const ActionButtons = ({
  index,
  tipoAviso,
  handleSavePosting,
  savePosting,
  showSavePosting,
  showNotInterested = false,
  setVisibleNotInterestedModal,
}) => {
  const { colors } = useContext<{ colors }>(ThemeContext)

  const preventAction = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <ActionContainer onClick={e => preventAction(e)}>
      {showSavePosting && tipoAviso !== 'bumeran' && (
        <CorazonContainer>
          <CorazonAnimado index={index} savePosting={savePosting} onClick={() => handleSavePosting()} />
        </CorazonContainer>
      )}
      {showNotInterested && (
        <>
          <Container onClick={() => setVisibleNotInterestedModal(true)}>
            <Icon size="18" color={colors.grey72} name="icon-light-thumbs-down" />
          </Container>
        </>
      )}
    </ActionContainer>
  )
}
