import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import {
  GET_ESTADO_CIVIL,
  GET_ESTADO_CIVIL_ERROR,
  GET_ESTADO_CIVIL_SUCCESS,
  GET_GENEROS,
  GET_GENEROS_ERROR,
  GET_GENEROS_SUCCESS,
  GET_PAISES,
  GET_PAISES_ERROR,
  GET_PAISES_SUCCESS,
  GET_TIPO_DOCUMENTO,
  GET_TIPO_DOCUMENTO_ERROR,
  GET_TIPO_DOCUMENTO_SUCCESS,
  GET_PROVINCIAS,
  GET_PROVINCIAS_ERROR,
  GET_PROVINCIAS_SUCCESS,
  GET_LOCALIDADES_SUCCESS,
  GET_LOCALIDADES_ERROR,
  GET_LOCALIDADES,
  GET_INSTITUCIONES_EDUCATIVAS,
  GET_INSTITUCIONES_EDUCATIVAS_SUCCESS,
  GET_INSTITUCIONES_EDUCATIVAS_ERROR,
  GET_TIPOS_ESTUDIO,
  GET_TIPOS_ESTUDIO_SUCCESS,
  GET_TIPOS_ESTUDIO_ERROR,
  GET_AREAS_ESTUDIO,
  GET_AREAS_ESTUDIO_SUCCESS,
  GET_AREAS_ESTUDIO_ERROR,
  GET_ESTADOS_ESTUDIO,
  GET_ESTADOS_ESTUDIO_SUCCESS,
  GET_ESTADOS_ESTUDIO_ERROR,
  GET_IDIOMAS_SUCCESS,
  GET_IDIOMAS_ERROR,
  GET_IDIOMAS,
  GET_AREAS,
  GET_AREAS_SUCCESS,
  GET_AREAS_ERROR,
  GET_SUBAREAS,
  GET_SUBAREAS_SUCCESS,
  GET_SUBAREAS_ERROR,
  GET_INDUSTRIAS,
  GET_INDUSTRIAS_SUCCESS,
  GET_INDUSTRIAS_ERROR,
  GET_NIVEL_PUESTO,
  GET_NIVEL_PUESTO_SUCCESS,
  GET_NIVEL_PUESTO_ERROR,
  GET_NIVELES_IDIOMA,
  GET_NIVELES_IDIOMA_ERROR,
  GET_NIVELES_IDIOMA_SUCCESS,
  StaticOption,
  GetNivelesIdiomaError,
  GetNivelesIdiomaSuccess,
  GetNivelesIdioma,
  GET_TIPOS_LICENCIAS_SUCCESS,
  GET_TIPOS_LICENCIAS_ERROR,
  GET_TIPOS_LICENCIAS,
  GetCanalesDeContacto,
  GET_CANALES_DE_CONTACTO,
  GET_CANALES_DE_CONTACTO_SUCCESS,
  GET_CANALES_DE_CONTACTO_ERROR,
} from '../types/statics'

function* getEstadoCivil() {
  try {
    const response = yield call(services.statics.GetEstadoCivil)
    yield put({ type: GET_ESTADO_CIVIL_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_ESTADO_CIVIL_ERROR, error })
  }
}

function* getPaises() {
  try {
    const response = yield call(services.statics.GetPaises)
    yield put({ type: GET_PAISES_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_PAISES_ERROR, error })
  }
}

function* getProvincias({ payload: idPais }) {
  try {
    const response = yield call(services.statics.GetProvincias, idPais)
    yield put({ type: GET_PROVINCIAS_SUCCESS, payload: { [idPais]: response } })
  } catch (error) {
    yield put({ type: GET_PROVINCIAS_ERROR, error })
  }
}

function* getLocalidades({ payload: idProvincia }) {
  try {
    const response = yield call(services.statics.GetLocalidades, idProvincia)
    yield put({ type: GET_LOCALIDADES_SUCCESS, payload: { [idProvincia]: response } })
  } catch (error) {
    yield put({ type: GET_LOCALIDADES_ERROR, error })
  }
}

function* getTipoDocumento() {
  try {
    const response = yield call(services.statics.GetTipoDocumento)
    yield put({ type: GET_TIPO_DOCUMENTO_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_TIPO_DOCUMENTO_ERROR, error })
  }
}

function* getGeneros() {
  try {
    const response = yield call(services.statics.GetGeneros)
    yield put({ type: GET_GENEROS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_GENEROS_ERROR, error })
  }
}

function* getTiposLicencias() {
  try {
    const response = yield call(services.statics.GetTipoLicencias)
    yield put({ type: GET_TIPOS_LICENCIAS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_TIPOS_LICENCIAS_ERROR, error })
  }
}

function* getInstitucionesEducativas({ payload: paisId }) {
  try {
    const response = yield call(services.statics.GetInstitucionesEducativas, paisId)
    yield put({ type: GET_INSTITUCIONES_EDUCATIVAS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_INSTITUCIONES_EDUCATIVAS_ERROR, error })
  }
}

function* getTiposEstudio({ payload: paisId }) {
  try {
    const response = yield call(services.statics.GetTiposEstudio, paisId)
    yield put({ type: GET_TIPOS_ESTUDIO_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_TIPOS_ESTUDIO_ERROR, error })
  }
}

function* getAreasEstudio() {
  try {
    const response = yield call(services.statics.GetAreasEstudio)
    yield put({ type: GET_AREAS_ESTUDIO_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_AREAS_ESTUDIO_ERROR, error })
  }
}

function* getEstadosEstudio() {
  try {
    const response = yield call(services.statics.GetEstadosEstudio)
    yield put({ type: GET_ESTADOS_ESTUDIO_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_ESTADOS_ESTUDIO_ERROR, error })
  }
}

function* getIdiomas() {
  try {
    const response = yield call(services.statics.GetIdiomas)
    yield put({ type: GET_IDIOMAS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_IDIOMAS_ERROR, error })
  }
}

function* getNivelesIdioma() {
  try {
    const response: StaticOption[] = yield call(services.statics.GetNivelesIdiomas)
    yield put<GetNivelesIdiomaSuccess>({ type: GET_NIVELES_IDIOMA_SUCCESS, payload: response })
  } catch (error) {
    yield put<GetNivelesIdiomaError>({ type: GET_NIVELES_IDIOMA_ERROR, payload: null })
  }
}

function* getAreas({ payload: paisId }) {
  try {
    const response = yield call(services.statics.GetAreas, paisId)
    yield put({ type: GET_AREAS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_AREAS_ERROR, error })
  }
}

function* getSubareas({ payload: areaId }) {
  try {
    const response = yield call(services.statics.GetSubAreas, areaId)
    yield put({ type: GET_SUBAREAS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_SUBAREAS_ERROR, error })
  }
}

function* getIndustrias() {
  try {
    const response = yield call(services.statics.GetIndustrias)
    yield put({ type: GET_INDUSTRIAS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_INDUSTRIAS_ERROR, error })
  }
}

function* getNivelPueso() {
  try {
    const response = yield call(services.statics.GetNivelPuesto)
    yield put({ type: GET_NIVEL_PUESTO_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_NIVEL_PUESTO_ERROR, error })
  }
}

function* getCanalesDeContacto() {
  try {
    const response = yield call(services.statics.GetCanalesDeContacto)
    yield put({ type: GET_CANALES_DE_CONTACTO_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_CANALES_DE_CONTACTO_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* statics() {
  yield takeLatest<any>(GET_ESTADO_CIVIL, getEstadoCivil)
  yield takeLatest<any>(GET_PAISES, getPaises)
  yield takeLatest<any>(GET_PROVINCIAS, getProvincias)
  yield takeLatest<any>(GET_LOCALIDADES, getLocalidades)
  yield takeLatest<any>(GET_TIPO_DOCUMENTO, getTipoDocumento)
  yield takeLatest<any>(GET_GENEROS, getGeneros)
  yield takeLatest<any>(GET_TIPOS_LICENCIAS, getTiposLicencias)
  yield takeLatest<any>(GET_INSTITUCIONES_EDUCATIVAS, getInstitucionesEducativas)
  yield takeLatest<any>(GET_TIPOS_ESTUDIO, getTiposEstudio)
  yield takeLatest<any>(GET_AREAS_ESTUDIO, getAreasEstudio)
  yield takeLatest<any>(GET_ESTADOS_ESTUDIO, getEstadosEstudio)
  yield takeLatest<any>(GET_IDIOMAS, getIdiomas)
  yield takeLatest<GetNivelesIdioma>(GET_NIVELES_IDIOMA, getNivelesIdioma)
  yield takeLatest<any>(GET_AREAS, getAreas)
  yield takeLatest<any>(GET_SUBAREAS, getSubareas)
  yield takeLatest<any>(GET_INDUSTRIAS, getIndustrias)
  yield takeLatest<any>(GET_NIVEL_PUESTO, getNivelPueso)
  yield takeLatest<GetCanalesDeContacto>(GET_CANALES_DE_CONTACTO, getCanalesDeContacto)
}
