import {
  StaticState,
  StaticActions,
  initialState,
  GET_ESTADO_CIVIL,
  GET_ESTADO_CIVIL_SUCCESS,
  GET_ESTADO_CIVIL_ERROR,
  GET_PAISES,
  GET_PAISES_SUCCESS,
  GET_PAISES_ERROR,
  GET_TIPO_DOCUMENTO_ERROR,
  GET_TIPO_DOCUMENTO_SUCCESS,
  GET_TIPO_DOCUMENTO,
  GET_GENEROS,
  GET_GENEROS_SUCCESS,
  GET_GENEROS_ERROR,
  GET_PROVINCIAS,
  GET_PROVINCIAS_SUCCESS,
  GET_PROVINCIAS_ERROR,
  GET_LOCALIDADES,
  GET_LOCALIDADES_SUCCESS,
  GET_LOCALIDADES_ERROR,
  GET_INSTITUCIONES_EDUCATIVAS,
  GET_INSTITUCIONES_EDUCATIVAS_SUCCESS,
  GET_INSTITUCIONES_EDUCATIVAS_ERROR,
  GET_TIPOS_ESTUDIO,
  GET_TIPOS_ESTUDIO_SUCCESS,
  GET_TIPOS_ESTUDIO_ERROR,
  GET_AREAS_ESTUDIO,
  GET_AREAS_ESTUDIO_SUCCESS,
  GET_AREAS_ESTUDIO_ERROR,
  GET_ESTADOS_ESTUDIO,
  GET_ESTADOS_ESTUDIO_SUCCESS,
  GET_ESTADOS_ESTUDIO_ERROR,
  GET_IDIOMAS,
  GET_IDIOMAS_SUCCESS,
  GET_IDIOMAS_ERROR,
  GET_AREAS,
  GET_AREAS_SUCCESS,
  GET_AREAS_ERROR,
  GET_SUBAREAS,
  GET_SUBAREAS_SUCCESS,
  GET_SUBAREAS_ERROR,
  GET_INDUSTRIAS,
  GET_INDUSTRIAS_SUCCESS,
  GET_INDUSTRIAS_ERROR,
  GET_NIVEL_PUESTO,
  GET_NIVEL_PUESTO_SUCCESS,
  GET_NIVEL_PUESTO_ERROR,
  GET_NIVELES_IDIOMA,
  GET_NIVELES_IDIOMA_SUCCESS,
  GET_NIVELES_IDIOMA_ERROR,
  GET_TIPOS_LICENCIAS,
  GET_TIPOS_LICENCIAS_SUCCESS,
  GET_TIPOS_LICENCIAS_ERROR,
  GET_CANALES_DE_CONTACTO,
  GET_CANALES_DE_CONTACTO_SUCCESS,
  GET_CANALES_DE_CONTACTO_ERROR,
} from '../types/statics'
import { status } from '../types/status'

const StaticReducer = (state: StaticState = initialState, action: StaticActions): StaticState => {//NOSONAR
  switch (action.type) {
    case GET_ESTADO_CIVIL:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_ESTADO_CIVIL_SUCCESS:
      return {
        ...state,
        estadoCivil: action.payload,
        loading: false,
        error: false,
      }
    case GET_ESTADO_CIVIL_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_PAISES:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_PAISES_SUCCESS:
      return {
        ...state,
        paises: action.payload,
        loading: false,
        error: false,
      }
    case GET_PAISES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_TIPO_DOCUMENTO:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_TIPO_DOCUMENTO_SUCCESS:
      return {
        ...state,
        tipoDocumento: action.payload,
        loading: false,
        error: false,
      }
    case GET_TIPO_DOCUMENTO_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_GENEROS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_GENEROS_SUCCESS:
      return {
        ...state,
        generos: action.payload,
        loading: false,
        error: false,
      }
    case GET_GENEROS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case GET_TIPOS_LICENCIAS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_TIPOS_LICENCIAS_SUCCESS:
      return {
        ...state,
        tiposLicencias: action.payload,
        loading: false,
        error: false,
      }
    case GET_TIPOS_LICENCIAS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_PROVINCIAS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_PROVINCIAS_SUCCESS:
      return {
        ...state,
        provincias: { ...state.provincias, ...action.payload },
        loading: false,
        error: false,
      }
    case GET_PROVINCIAS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_LOCALIDADES:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_LOCALIDADES_SUCCESS:
      return {
        ...state,
        localidades: { ...state.localidades, ...action.payload },
        loading: false,
        error: false,
      }
    case GET_LOCALIDADES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_INSTITUCIONES_EDUCATIVAS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_INSTITUCIONES_EDUCATIVAS_SUCCESS:
      return {
        ...state,
        institucionesEducativas: action.payload,
        loading: false,
        error: false,
      }
    case GET_INSTITUCIONES_EDUCATIVAS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_TIPOS_ESTUDIO:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_TIPOS_ESTUDIO_SUCCESS:
      return {
        ...state,
        tiposEstudio: action.payload,
        loading: false,
        error: false,
      }
    case GET_TIPOS_ESTUDIO_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_AREAS_ESTUDIO:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_AREAS_ESTUDIO_SUCCESS:
      return {
        ...state,
        areasEstudio: action.payload,
        loading: false,
        error: false,
      }
    case GET_AREAS_ESTUDIO_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_ESTADOS_ESTUDIO:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_ESTADOS_ESTUDIO_SUCCESS:
      return {
        ...state,
        estadosEstudio: action.payload,
        loading: false,
        error: false,
      }
    case GET_ESTADOS_ESTUDIO_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_IDIOMAS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_IDIOMAS_SUCCESS:
      return {
        ...state,
        idiomas: action.payload,
        loading: false,
        error: false,
      }
    case GET_IDIOMAS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case GET_NIVELES_IDIOMA:
      return {
        ...state,
        nivelesIdioma: {
          ...state.nivelesIdioma,
          status: status.loading,
        },
      }
    case GET_NIVELES_IDIOMA_SUCCESS:
      return {
        ...state,
        nivelesIdioma: {
          items: action.payload,
          status: status.success,
        },
      }
    case GET_NIVELES_IDIOMA_ERROR:
      return {
        ...state,
        nivelesIdioma: {
          ...state.nivelesIdioma,
          status: status.error,
        },
      }
    case GET_AREAS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_AREAS_SUCCESS:
      return {
        ...state,
        areas: action.payload,
        loading: false,
        error: false,
      }
    case GET_AREAS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_SUBAREAS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_SUBAREAS_SUCCESS:
      return {
        ...state,
        subareas: action.payload,
        loading: false,
        error: false,
      }
    case GET_SUBAREAS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_INDUSTRIAS:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_INDUSTRIAS_SUCCESS:
      return {
        ...state,
        industrias: action.payload,
        loading: false,
        error: false,
      }
    case GET_INDUSTRIAS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case GET_NIVEL_PUESTO:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_NIVEL_PUESTO_SUCCESS:
      return {
        ...state,
        nivelesPuesto: action.payload,
        loading: false,
        error: false,
      }
    case GET_NIVEL_PUESTO_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case GET_CANALES_DE_CONTACTO:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case GET_CANALES_DE_CONTACTO_SUCCESS:
      return {
        ...state,
        canalesDeContacto: action.payload,
        loading: false,
        error: false,
      }
    case GET_CANALES_DE_CONTACTO_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default StaticReducer
