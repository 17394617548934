import { merge } from 'lodash'
import es from '../es'
import modalCrearAlerta from './modal-crear-alerta'
import metatagsListado from './metatags-listado'
import searchBar from './search-bar'
import busquedaEncontrada from './busqueda-encontrada'
import filtersSettings from './filters-settings'
import fichaAvisoEmptyState from './ficha-aviso-empty-state'
import { seoWordings as seoWordingsEc } from './seo-wordings'
import { seoWordings as seoWordingsEs } from '../es/seo-wordings'
import crearAlerta from './crear-alerta'
import curriculumEs from '../es/curriculum'
import curriculumEc from './curriculum'
import { currencyMask } from './currency-mask'
import postulacionesEs from '../es/postulaciones'
import postulacionesEc from './postulaciones'
import { products as productsEs } from '../es/products'
import { products as productsEc } from './products'
import filters from './filters'
import filtersEs from '../es/filters'
import signUpEs from '../es/sign-up'
import signUpEc from './sign-up'
import { nulinga as nulingaEs } from '../es/nulinga'
import { nulinga as nulingaEc } from './nulinga'
import { paymentMP as paymentMPEs } from '../es/payment-mp'
import { paymentMP as paymentMPEc } from './payment-mp'
import { productLanding as productLandingEs } from '../es/product-landing'
import { productLanding as productLandingEc } from './product-landing'
import { perfil as perfilEs } from '../es/perfil'
import { perfil as perfilEc } from './perfil'
import fichaAviso from './ficha-aviso'
import fichaAvisoEs from '../es/ficha-aviso'
import feria from './feria'
import feriaEs from '../es/feria'
import companySummary from './company-summary'
import companySummaryES from '../es/company-summary'

const ec = {
  ...es,
  cv: 'HV',
  ...modalCrearAlerta,
  ...metatagsListado,
  ...searchBar,
  ...busquedaEncontrada,
  ...filtersSettings,
  ...fichaAvisoEmptyState,
  ...crearAlerta,
  currencyMask,
  'Postulantes Title': 'Empleos en Ecuador: trabajo y ofertas de empleo - Multitrabajos 2020',
  'Postulantes Meta Desciption':
    'Busca empleos en 2020 en Ecuador en la bolsa de trabajo más grande del país. Encuentra ofertas de empleo y trabajos en las mejores empresas en Multitrabajos.',
  Buscar: 'Buscar EC',
  'Ir al inicio': 'Ir al inicio EC',
  'Home Sponsor Title Part1': 'Miles de empresas',
  'Home Sponsor Title Part2': 'necesitan tu talento',
  portal: {
    currency: '$',
    'currency-format': 'XXX.XXX.XXX,XX',
  },
  'Ficha Aviso Title': '{{nombreAviso}}, en {{empresa}} - {{mes}} {{anio}} - multitrabajos.com',
  'Ficha Aviso Meta Description':
    'Oferta de trabajo {{nombreAviso}} en {{empresa}}, publicado en {{mes}} {{anio}}, en multitrabajos Empleos.',
  seoWordings: merge({}, seoWordingsEs, seoWordingsEc),
  curriculum: merge({}, curriculumEs, curriculumEc),
  postulaciones: merge({}, postulacionesEs, postulacionesEc),
  products: merge({}, productsEs, productsEc),
  signUp: merge({}, signUpEs, signUpEc),
  nulinga: merge({}, nulingaEs, nulingaEc),
  paymentMP: merge({}, paymentMPEs, paymentMPEc),
  productLanding: merge({}, productLandingEs, productLandingEc),
  ...merge({}, { ...filtersEs }, { ...filters }),
  perfil: merge({}, perfilEs, perfilEc),
  fichaAviso: merge({}, fichaAvisoEs, fichaAviso),
  feria: merge({}, feriaEs, feria),
  companySummary: merge({}, companySummaryES, companySummary),
}

export default ec
