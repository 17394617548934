import React, { useContext, useState } from 'react'
import Text from '@navent-jobs/ui-kit/components/text'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { ThemeContext } from 'styled-components'

import {
  ApplicationStatusWrapper,
  PostuladoContainer,
  ReadContainer,
  Tooltip,
  CustomContainer,
  ContainerPostulado,
} from '../../mixins'

import { ActionButtons } from '../ActionButtons'

// muestra el estado de postulacion representado por un icon: completa e incompleta
// si no esta postulado muestra icon para guardar aviso
const ApplicationStatus = ({
  index,
  tipoAviso,
  read,
  estadoPostulacionRealizada,
  estadoPostulacionEspera,
  saveJobPostingEnabled,
  handleSavePosting,
  savePosting,
  isDesktop,
  mobileFormat,
  notInterestedJobPostingEnabled = false,
  setVisibleNotInterestedModal,
}) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const [showTooltip, setShowTooltip] = useState(false)
  const isMobileFormat = !isDesktop || mobileFormat

  const tooltipEnter = () => {
    isDesktop && setShowTooltip(true)
  }
  const tooltipLeave = () => {
    isDesktop && setShowTooltip(false)
  }

  if (tipoAviso === 'zonajobs') return null
  return (
    <>
      <ApplicationStatusWrapper
        isMobileFormat={isMobileFormat && (read || estadoPostulacionRealizada || estadoPostulacionEspera)}
      >
        {read && !(estadoPostulacionRealizada || estadoPostulacionEspera) && (
          <CustomContainer>
            <ReadContainer>
              <Icon name="icon-light-checkmark-double" size="16" color={colors.grey0} />
            </ReadContainer>
            {isMobileFormat && (
              <Text size="sm" fontWeight="semibold" variant="gray84" as="span">
                Leído
              </Text>
            )}
          </CustomContainer>
        )}
        {estadoPostulacionRealizada && (
          <PostuladoContainer>
            {showTooltip && (
              <Tooltip>
                <Text size="xs" fontWeight="semibold" variant="gray0" as="span">
                  Ya Postulado
                </Text>
              </Tooltip>
            )}
            <ContainerPostulado onMouseEnter={tooltipEnter} onMouseLeave={tooltipLeave}>
              <Icon name="icon-light-checkbox-checked" size="24" color={colors.secondary.normal} />
              {isMobileFormat && (
                <Text size="sm" fontWeight="semibold" variant="gray84" as="span">
                  Postulado
                </Text>
              )}
            </ContainerPostulado>
          </PostuladoContainer>
        )}
        {estadoPostulacionEspera && (
          <CustomContainer>
            <Icon name="icon-light-alert-triangle" size="24" color={colors.danger.normal} />
            {isMobileFormat && (
              <Text size="sm" fontWeight="semibold" variant="danger" as="span">
                Incompleto
              </Text>
            )}
          </CustomContainer>
        )}

        <ActionButtons
          index={index}
          tipoAviso={tipoAviso}
          showSavePosting={saveJobPostingEnabled}
          handleSavePosting={handleSavePosting}
          savePosting={savePosting}
          showNotInterested={notInterestedJobPostingEnabled}
          setVisibleNotInterestedModal={setVisibleNotInterestedModal}
        />
      </ApplicationStatusWrapper>
    </>
  )
}

export default ApplicationStatus
