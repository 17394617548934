import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Button } from '@navent-jobs/ui-kit/components/button'
import { avisosSkin } from './constants/mixins'
import { colorPalletForPructs } from '../../constants/products/color-pallet-for-products'

export const CardComponentWrapper = styled('div')`
  ${props => {
    const { tipoAviso } = props
    const avisoSkin = avisosSkin(props)
    return avisoSkin[tipoAviso]
  }}
`

export const CardContentWrapper = styled('a')`
  display: block;
  position: relative;
  ${({ sameHeight }) => sameHeight && 'height: 100%;'}

  &:hover {
    text-decoration: none;
  }
`

export const CardContainer = styled<{ tipoAviso: string }>('div')`
  display: flex;
  flex-direction: column;
  padding: 12px 0px 12px 6px;
  gap: 12px 0;
  ${({ sameHeight }) => sameHeight && 'height: 100%; justify-content: space-between;'}

  ${({ mobileFormat, theme, tipoAviso }) =>
    !mobileFormat &&
    `
    @media (min-width: ${theme.screen.xlMin}) {
      ${
        (tipoAviso === 'home' || tipoAviso === 'destacado' || tipoAviso === 'simple' || tipoAviso === 'tecnologia') &&
        'min-height: 116px'
      };
      padding: 16px 4px 16px 12px;
      flex-direction: row;
    }
  `}
`

export const InfoCol = styled('div')`
  display: flex;
  flex-direction: column;

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    justify-content: space-between;
  }`}
`

export const DataRow = styled('div')`
  display: flex;
  align-items: center;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }`}
`

export const DataIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.grey84};
  margin-right: 4px;

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
      margin-right: 8px;
    }`}
`

export const Highlight = styled('div')`
  display: flex;
  align-items: center;
  background: ${({ portal, tipoAviso }) => colorPalletForPructs?.[portal].aviso?.[tipoAviso]};
  color: white;
  padding: 16px 0px;
  height: 100%;
  width: 6px;
  border-radius: 8px 0px 0px 8px;
  position: absolute;
  left: -1px;
  right: -1px;
  top: 1px;
  transition: all 0.3s ease-in-out;
`

export const ButtonCustom = styled(Button)`
  border: 0px;
  right: 7px;
`

export const ApplicationStatusWrapper = styled('div')`
  display: flex;
  justify-content: ${({ isMobileFormat }) => (isMobileFormat ? 'space-between' : 'flex-end')};
  width: 100%;
  gap: 24px;
`

export const Tooltip = styled('div')`
  position: relative;
  z-index: 20;
  background-color: ${({ theme }) => theme.colors.secondary.normal};
  padding: 8px 12px;
  margin-right: 7px;
  border-radius: 12px;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.05);
  width: max-content;
  margin-top: -6px;

  &:after {
    content: '';
    position: absolute;
    top: 35%;
    right: -7px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 7px solid ${({ theme }) => theme.colors.secondary.normal};
    border-bottom: 5px solid transparent;
  }
`

export const PostuladoContainer = styled('div')`
  display: flex;
`

export const ReadContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  border-radius: 4px;
  background: #858590;
  height: 100%;
`

export const LoaderContainer = styled('div')`
  width: 100%;
  max-height: 172px;
  border-radius: 8px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.12);
  background: white;
  margin: 0 0 15px;
`

export const DataInfo = styled('span')`
  &,
  h3 {
    white-space: nowrap;
  }
`

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`

export const ContainerPostulado = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const Status = styled.div`
  display: flex;
  flex-direction: row;
`
