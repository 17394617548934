import React from 'react'
import { isEmpty } from 'lodash'

// ui-kit
import { Icon } from '@navent-jobs/ui-kit/components/icon'

// mixins
import {
  ComponentContainer,
  LogoWrapper,
  Logo,
  Title,
  TitleContainer,
  LogoAndTitle,
  ItemGroup,
  Quantity,
  DataContainer,
  Label,
  LocationContainer,
  ImgGptw,
  SeguirContainer,
  FlexContainer,
} from './mixins'

// components
import urlLogoDefault from '../../../../../assets/images/company-default-logo.svg'
import CompanyRating from '../../../rating'
import logoGPTW from '../../../../../assets/images/calificar-empresas/GPTW_Logo.svg'
import SeguirEmpresa from '../../../extended/components/SeguirEmpresa'
import BusquedaActivaChip from '../../../../busqueda-activa-chip'

// constants
import features from '../../../../../constants/features-per-country'
import { SITE_ID } from '../../../../../constants'

const MAX_CHARS_TITLE = 70

const gptwEnabled = features[SITE_ID || 'BMAR'].gptw.enabled

const Component = ({
  companyId,
  logo,
  name,
  jobPostingsQuantity,
  industry,
  location,
  reviewAverage,
  gptw,
  following,
  activeSearch,
}) => {
  const hasQuantity = jobPostingsQuantity >= 1
  const showGptw = gptwEnabled && gptw

  return (
    <ComponentContainer>
      <div>
        <LogoAndTitle>
          <LogoWrapper>
            <Logo
              src={!isEmpty(logo) ? logo : urlLogoDefault}
              width="100%"
              height="100%"
              alt={`logo-company-${name}`}
            />
          </LogoWrapper>

          <TitleContainer>
            <Title>{name.length > MAX_CHARS_TITLE ? `${name.substring(0, MAX_CHARS_TITLE)}...` : name}</Title>
          </TitleContainer>

          {showGptw && <ImgGptw src={logoGPTW} alt="logo GPTW" />}
        </LogoAndTitle>

        {!showGptw && reviewAverage && <CompanyRating reviewAverage={reviewAverage} />}

        <DataContainer>
          <ItemGroup>
            <Icon name="icon-light-list" size="20" color={!hasQuantity ? '#C3C2C8' : '#29293D'} />
            <Quantity hasQuantity={hasQuantity}>{hasQuantity ? jobPostingsQuantity : 'Sin'} avisos activos</Quantity>
          </ItemGroup>

          {industry && (
            <ItemGroup marginBottom={!location}>
              <Icon name="icon-light-layers" size="20" color="#29293D" />
              <Label>{industry}</Label>
            </ItemGroup>
          )}

          {location && (
            <LocationContainer>
              <Icon name="icon-light-location-pin" size="20" color="#29293D" />
              <Label>{location}</Label>
            </LocationContainer>
          )}
        </DataContainer>

        {activeSearch && (
          <FlexContainer>
            <BusquedaActivaChip busquedaActiva={activeSearch} />
          </FlexContainer>
        )}
      </div>

      <SeguirContainer>
        <SeguirEmpresa empresaId={companyId} siguiendo={following} />
      </SeguirContainer>
    </ComponentContainer>
  )
}

export default Component
