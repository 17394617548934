import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { isEmpty } from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import Text from '@navent-jobs/ui-kit/components/text'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { planConfig } from '../../constants/planConfig'
import { InfoCol } from '../../mixins'
import {
  MainInfoCard,
  Company,
  CardHeader,
  LogoWrapper,
  Logo,
  Title,
  Description,
  DataRowTitle,
  CardBody,
  HeaderCol,
  HeaderContainer,
  DateRow,
  HeaderColContainer,
  DataColMobile,
  ClampText,
  ImgGPTW,
  GPTWWrapper,
  RowGap,
} from './mixins'
import HeaderTagData from '../HeaderTagData'
import features from '../../../../constants/features-per-country'
import { SITE_ID } from '../../../../constants'
import logoGPTW from '../../../../assets/images/calificar-empresas/GPTW_Logo.svg'

const gptwEnabled = features[SITE_ID ?? 'BMAR'].gptw.enabled

const defineCompanyName = ({ confidencial, tipoAviso, empresa, mobileFormat }) => {
  if (confidencial) {
    return (
      <Company mobileFormat={mobileFormat}>
        <Text size="sm" fontWeight="semibold" as="h3">
          Confidencial
        </Text>
      </Company>
    )
  }

  return (
    planConfig[tipoAviso].showCompanyName && (
      <Company mobileFormat={mobileFormat}>
        <Text size="sm" fontWeight="semibold" as="h3">
          {empresa}
        </Text>
      </Company>
    )
  )
}

const makeTitleRow = props => {
  const { tipoAviso, empresa, confidencial, promedioEmpresa, mobileFormat, colors, altaRevisionPerfiles } = props

  return (
    <DataRowTitle mobileFormat={mobileFormat}>
      {defineCompanyName({ confidencial, tipoAviso, empresa, mobileFormat })}

      {((!confidencial && promedioEmpresa) || altaRevisionPerfiles) && (
        <RowGap>
          {!confidencial && promedioEmpresa && (
            <DateRow>
              <Icon name="icon-bold-star" color="#FAB306" size="16" />
              <Text size="xs" fontWeight="semibold" variant="gray60" as="h3">
                {promedioEmpresa.toFixed(1)}
              </Text>
            </DateRow>
          )}
          {altaRevisionPerfiles && (
            <DateRow>
              <Icon name="icon-light-trending-up" color={colors.extra.normal} size="16" />
              <ClampText>
                <Text size="xs" fontWeight="semibold" variant="extra">
                  Alta revisión de perfiles
                </Text>
              </ClampText>
            </DateRow>
          )}
        </RowGap>
      )}
    </DataRowTitle>
  )
}

const showDescription = (show, detalle, mobileFormat, clamp) => {
  return (
    show && (
      <CardBody>
        <Description clamp={clamp} mobileFormat={mobileFormat}>
          <Text size="sm">{ReactHtmlParser(detalle)}</Text>
        </Description>
      </CardBody>
    )
  )
}

const Component = ({
  tipoAviso,
  confidencial,
  logoURL,
  estadoPostulacion,
  estadoPostulacionEspera,
  isDesktop,
  empresa,
  cantidadDeDias,
  avisoNuevo,
  titulo,
  detalle,
  promedioEmpresa,
  isRead,
  mobileFormat,
  clamp,
  cantidadVacantes,
  hideCompanyRow = false,
  postulacionRapida = false,
  altaRevisionPerfiles = false,
  gptwUrl,
}) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const isDesktopFormat = isDesktop && !mobileFormat
  const showLogo = !isEmpty(logoURL) && planConfig[tipoAviso].showLogo
  const showGptw = gptwEnabled && !!gptwUrl
  const showDescriptionSection = planConfig[tipoAviso].showDescription && !isEmpty(detalle)

  const showTitle = (
    <Title
      mobileFormat={mobileFormat}
      buttons={false}
      tipoAviso={tipoAviso}
      hasDescription={planConfig[tipoAviso].showDescription && !isEmpty(detalle)}
      withIcon={estadoPostulacion || estadoPostulacionEspera || isRead}
    >
      <Text size="md" fontWeight="semibold" variant="secondary" as="h3">
        {titulo}
      </Text>
    </Title>
  )

  return (
    <HeaderColContainer>
      <HeaderContainer mobileFormat={mobileFormat} tipoAviso={tipoAviso}>
        {!isDesktopFormat && (
          <>
            <DataColMobile>
              <HeaderTagData
                estadoPostulacion={estadoPostulacion}
                isRead={isRead}
                avisoNuevo={avisoNuevo}
                cantidadVacantes={cantidadVacantes}
                cantidadDeDias={cantidadDeDias}
                postulacionRapida={postulacionRapida}
              />
            </DataColMobile>
            <DataColMobile>{showTitle}</DataColMobile>
          </>
        )}

        <MainInfoCard mobileFormat={mobileFormat}>
          {isDesktopFormat && (
            <HeaderTagData
              estadoPostulacion={estadoPostulacion}
              isRead={isRead}
              avisoNuevo={avisoNuevo}
              cantidadVacantes={cantidadVacantes}
              cantidadDeDias={cantidadDeDias}
              postulacionRapida={postulacionRapida}
            />
          )}

          <CardHeader
            hasCompanyName={planConfig[tipoAviso].showCompanyName && !confidencial}
            hasLogo={showLogo || showGptw}
          >
            {showGptw && (
              <GPTWWrapper>
                <ImgGPTW src={logoGPTW} alt="logo GPTW" />
              </GPTWWrapper>
            )}
            {showLogo && (
              <LogoWrapper>
                <Logo src={logoURL} />
              </LogoWrapper>
            )}

            <HeaderCol hasLogo={showLogo || showGptw}>
              {isDesktopFormat && showTitle}
              {!hideCompanyRow &&
                makeTitleRow({
                  tipoAviso,
                  empresa,
                  confidencial,
                  promedioEmpresa,
                  mobileFormat,
                  colors,
                  altaRevisionPerfiles,
                })}
            </HeaderCol>
          </CardHeader>

          {isDesktopFormat && showDescription(showDescriptionSection, detalle, mobileFormat, clamp)}
        </MainInfoCard>
      </HeaderContainer>

      {!isDesktopFormat && showDescriptionSection && (
        <DataColMobile>
          <InfoCol mobileFormat={mobileFormat}>
            {showDescription(showDescriptionSection, detalle, mobileFormat, clamp)}
          </InfoCol>
        </DataColMobile>
      )}
    </HeaderColContainer>
  )
}

export default Component
