import { ValidateRut, VALIDATE_RUT, VALIDATE_RUT_RESET, ValidateRutReset } from '../types/rut'

export const validateRut = (rut: string): ValidateRut => {
  return {
    type: VALIDATE_RUT,
    payload: rut,
  }
}

export const validateRutReset = (): ValidateRutReset => {
  return {
    type: VALIDATE_RUT_RESET,
    payload: null,
  }
}
