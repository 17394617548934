const feria = {
  header: {
    contact: 'Bienvenidos a la feria de empleos más grande de todo {{pais}}.',
    register: 'Regístrate',
    title: '¡El clic que lo puede cambiar todo!',
  },
  headerUniv: {
    title: '¿Buscás trabajo?',
    description:
      'Llegó el momento de que hagas el clic que puede cambiar todo. Más de 30 empresas quieren conocerte.<0></0> Deslizá hacia abajo para conocer las empresas participantes y empezá a postularte a las ofertas que se ajusten a tu perfil.',
  },
  list: {
    title: 'Conoce todas las empresas participantes por industria',
    subtitle: 'Elige una industria para filtrar las empresas',
    searchPlaceholder: 'Ingresa una empresa',
  },
  register: {
    title: 'Regístrate y descubre un mundo de posibilidades profesionales.',
    button: 'Regístrate',
  },
  ficha: {
    enviar: 'Enviar {{cv}}',
    enviarPostulado: '{{cv}} enviado',
    error: 'Ocurrió un error inesperado',
    seccionAreas: 'Áreas de la empresa',
    postularme: 'Postularme',
  },
  conferences: {
    description: 'Te compartimos algunos consejos útiles para tu búsqueda.',
    replay: 'Revive la charla',
    soon: 'Muy pronto',
    videoTitle: 'Mira los videos',
    title: 'Tips para encontrar tu próximo trabajo',
  },
  description: {
    imageTitle: 'Descubre nuestra exclusiva sección de conferencias.',
    descriptionOne: 'Ahora puedes postularte desde cualquier lugar, las 24 hs del día.',
    descriptionTwo: '¡El trabajo que te gusta está aquí!',
  },
  info: {
    avisos: 'Avisos publicados',
    cantAvisos: '+50.000',
    cantEmpresas: '+5.000',
  },
  empty: {
    title: 'Esta empresa aún no ha publicado empleos',
    subtitle: 'Envía tu {{cv}} a su base de datos',
  },
  companies: {
    title: 'Empresas participantes',
  },
  lanzamiento: {
    encabezadoTitulo: '¡El clic que lo puede cambiar todo!',
    encabezadoDescripcion: 'Vuelve la feria de empleos más grande de {{pais}}',
    registrate: '¡Regístrate ya!',
    actualizarCv: 'Actualiza tu {{cv}}',
    ingresar: 'Ingresar',
  },
}

export default feria
