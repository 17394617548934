import auth from './authentification'
import applicant from './applicant'
import locations from './locations'
import seoFooter from './seo-footer'
import listadoAvisos from './listado-avisos'
import fichaAviso from './ficha-aviso-service'
import homePage from './home-page'
import seoWording from './seo-wordings'
import breadcrumbs from './breadcrumbs'
import statics from './statics'
import emailValidation from './emailValidation'
import curriculum from './curriculum'
import deleteaccount from './deleteaccount'
import account from './account'
import notificationSettings from './notification-settings'
import alertsSettings from './alerts-settings'
import postulaciones from './postulaciones'
import companySummaryAvisos from './company-summary'
import analysisCv from './analysis-cv'
import paymentInventory from './candidates-inventory'
import languageTest from './language-test'
import notifications from './notifications'
import avisosGuardadosService from './save-job-posting'
import listadoEmpresas from './listado-empresas'
import products from './products'
import confirmReference from './confirm-reference'
import review from './review'
import denuncia from './denuncia'
import promedioSalarial from './promedioSalarial'
import suscriptions from './suscripcion'
import cvParser from './cv-parser'
import empresasSeguidasService from './save-company'
import feriaService from './feria-service'
import disponibleInmediatoService from './disponible-inmediato'
import areaAds from './area-ads'
import rutValidation from './rutValidation'

export default {
  auth,
  applicant,
  locations,
  seoFooter,
  listadoAvisos,
  listadoEmpresas,
  homePage,
  seoWording,
  breadcrumbs,
  fichaAviso,
  statics,
  emailValidation,
  rutValidation,
  curriculum,
  deleteaccount,
  account,
  notificationSettings,
  alertsSettings,
  postulaciones,
  companySummaryAvisos,
  analysisCv,
  paymentInventory,
  languageTest,
  notifications,
  avisosGuardadosService,
  products,
  confirmReference,
  review,
  denuncia,
  promedioSalarial,
  suscriptions,
  cvParser,
  empresasSeguidasService,
  feriaService,
  disponibleInmediatoService,
  areaAds,
}
