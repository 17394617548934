const feria = {
  header: {
    contact: 'Bienvenidos a la feria de empleos más grande de todo Argentina.',
    register: 'Registrate',
  },
  list: {
    title: 'Conocé todas las empresas participantes por industria',
    subtitle: 'Elegí una industria para filtrar las empresas',
    searchPlaceholder: 'Ingresá una empresa',
  },
  register: {
    title: 'Registrate y descubrí un mundo de posibilidades profesionales.',
    button: 'Registrate',
  },
  conferences: {
    replay: 'Reviví la charla',
    videoTitle: 'Mirá los videos',
  },
  description: {
    imageTitle: 'Descubrí nuestros tips para encontrar tu próximo trabajo.',
    imageDescription: 'Descubrí tu próximo empleo en nuestra feria virtual.',
    descriptionOne: 'Ahora podés postularte desde cualquier lugar, las 24 hs del día.',
    descriptionTwo: '¡El trabajo que te gusta está acá!',
  },
  info: {
    avisos: 'Vacantes publicadas',
  },
  empty: {
    subtitle: 'Enviá tu {{cv}} a su base de datos',
  },
  lanzamiento: {
    registrate: '¡Registrate ya!',
    actualizarCv: 'Actualizá tu {{cv}}',
  },
}

export default feria
