export const mensajes = {
  title: 'Mensajes',
  sendMessageError: 'Hubo un error, vuelve a intentarlo.',
  placeholder: {
    input: 'Escribe tu mensaje',
  },
  form: {
    textHelper: 'Máximo: 1.000 caracteres.',
    errorMaxLength: 'Superaste el máximo de 1.000 caracteres.',
  },
  emptyState: {
    mainPageTitle: 'Todavía no tienes ningún mensaje',
    mainPageSubTitle: 'Cuando una empresa te contacte, los mensajes aparecerán en esta pantalla.',
    channel: {
      title: 'Selecciona una conversación para ver los mensajes o enviar uno nuevo',
    },
  },
  labels: {
    iconBBD: 'Búsqueda en base',
    chatTitle: 'Mensajes con',
    chatBBD: 'Te encontró por búsqueda en base',
    ctaSend: 'Enviar',
  },
  error: 'Hubo un error, vuelve a intentarlo.',
}
