export const VALIDATE_RUT = 'VALIDATE_RUT'
export const VALIDATE_RUT_RESET = 'VALIDATE_RUT_RESET'
export const VALIDATE_RUT_SUCCESS = 'VALIDATE_RUT_SUCCESS'
export const VALIDATE_RUT_ERROR = 'VALIDATE_RUT_ERROR'

export interface ValidateRutType {
  isValid: boolean
}

export interface RutState {
  response: ValidateRutType | null
  loading: boolean
  success: boolean
  error: boolean
}

export const initialState: RutState = {
  response: null,
  loading: false,
  success: false,
  error: false,
}

//  validate Rut
export interface ValidateRut {
  type: typeof VALIDATE_RUT
  payload: string
}
export interface ValidateRutSuccess {
  type: typeof VALIDATE_RUT_SUCCESS
  payload: ValidateRutType | null
}
export interface ValidateRutError {
  type: typeof VALIDATE_RUT_ERROR
  payload: boolean
}

export interface ValidateRutReset {
  type: typeof VALIDATE_RUT_RESET
  payload: null
}

export type RutActions = ValidateRut | ValidateRutSuccess | ValidateRutError | ValidateRutReset
