import React from 'react'
import { useTranslation } from 'react-i18next'
import Text from '@navent-jobs/ui-kit/components/text'

// mixins
import { GptwCard, LinkCustom, Logo, LogoContainer, TextContainer, CustomTextContainer } from './mixins'

// components
import logoWhiteGPTW from '../../../assets/images/calificar-empresas/GPTW_Logo_white.svg'

const BannerGptw = ({ url, isFichaAviso = false, mobileView = false }) => {
  const { t } = useTranslation()

  const Divider = () => (
    <div style={{ alignSelf: 'center', display: 'flex', paddingRight: 10, paddingTop: 16, paddingBottom: 16 }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="198" viewBox="0 0 4 198" fill="none">
        <path d="M2 0V198" stroke="#E02929" stroke-width="3" />
      </svg>
    </div>
  )
  return (
    <GptwCard isFichaAviso={isFichaAviso}>
      <LogoContainer mobileView={mobileView}>
        <Logo mobileView={mobileView} src={logoWhiteGPTW} alt="logo GPTW" />
      </LogoContainer>
      <Divider />

      <TextContainer mobileView={mobileView}>
        <CustomTextContainer>
          <Text size="xs" type="title" fontWeight="semibold">
            {t('companySummary.gptw.description')}
          </Text>
        </CustomTextContainer>
        <LinkCustom
          mobileView={mobileView}
          isFichaAviso={isFichaAviso}
          href={url}
          linkoptions={{ variant: 'primary', type: 'outline', size: 'medium', target: '_blank' }}
        >
          {t('companySummary.gptw.button')}
        </LinkCustom>
      </TextContainer>
    </GptwCard>
  )
}

export default BannerGptw
