import {
  GetEstadoCivil,
  GetGeneros,
  GetIdiomas,
  GetLocalidades,
  GetPaises,
  GetProvincias,
  GetTipoDocumento,
  GetInstitucionesEducativas,
  GetTiposEstudio,
  GetAreasEstudio,
  GetEstadosEstudio,
  GET_ESTADO_CIVIL,
  GET_GENEROS,
  GET_INSTITUCIONES_EDUCATIVAS,
  GET_LOCALIDADES,
  GET_PAISES,
  GET_PROVINCIAS,
  GET_TIPO_DOCUMENTO,
  GET_TIPOS_ESTUDIO,
  GET_AREAS_ESTUDIO,
  GET_ESTADOS_ESTUDIO,
  GET_IDIOMAS,
  GET_AREAS,
  GetAreas,
  GetSubareas,
  GET_SUBAREAS,
  GetIndustrias,
  GET_INDUSTRIAS,
  GET_NIVEL_PUESTO,
  GET_NIVELES_IDIOMA,
  GetNivelesIdioma,
  GET_TIPOS_LICENCIAS,
  GetTiposLicencias,
  GET_CANALES_DE_CONTACTO,
} from '../types/statics'

export const getEstadoCivil = (): GetEstadoCivil => {
  return {
    type: GET_ESTADO_CIVIL,
    payload: null,
  }
}

export const getPaises = (): GetPaises => {
  return {
    type: GET_PAISES,
    payload: null,
  }
}

export const getProvincias = (idPais: number): GetProvincias => {
  return {
    type: GET_PROVINCIAS,
    payload: idPais,
  }
}

export const getLocalidades = (idProvincia: number): GetLocalidades => {
  return {
    type: GET_LOCALIDADES,
    payload: idProvincia,
  }
}

export const getTipoDocumento = (): GetTipoDocumento => {
  return {
    type: GET_TIPO_DOCUMENTO,
    payload: null,
  }
}

export const getGeneros = (): GetGeneros => {
  return {
    type: GET_GENEROS,
    payload: null,
  }
}

export const getTiposLicencias = (): GetTiposLicencias => {
  return {
    type: GET_TIPOS_LICENCIAS,
    payload: null,
  }
}

export const getInstitucionesEducativas = (paisId: string): GetInstitucionesEducativas => {
  return {
    type: GET_INSTITUCIONES_EDUCATIVAS,
    payload: paisId,
  }
}

export const getTiposEstudio = (paisId: string): GetTiposEstudio => {
  return {
    type: GET_TIPOS_ESTUDIO,
    payload: paisId,
  }
}

export const getAreasEstudio = (): GetAreasEstudio => {
  return {
    type: GET_AREAS_ESTUDIO,
    payload: null,
  }
}

export const getEstadosEstudio = (): GetEstadosEstudio => {
  return {
    type: GET_ESTADOS_ESTUDIO,
    payload: null,
  }
}

export const getIdiomas = (): GetIdiomas => {
  return {
    type: GET_IDIOMAS,
    payload: null,
  }
}

export const getNivelesIdioma = (): GetNivelesIdioma => {
  return {
    type: GET_NIVELES_IDIOMA,
    payload: null,
  }
}

export const getAreas = (paisId: number): GetAreas => {
  return {
    type: GET_AREAS,
    payload: paisId,
  }
}

export const getSubareas = (areaId: number): GetSubareas => {
  return {
    type: GET_SUBAREAS,
    payload: areaId,
  }
}

export const getIndustrias = (): GetIndustrias => {
  return {
    type: GET_INDUSTRIAS,
    payload: null,
  }
}

export const getNivelPueso = () => {
  return {
    type: GET_NIVEL_PUESTO,
    payload: null,
  }
}

export const getCanalesDeContacto = () => {
  return {
    type: GET_CANALES_DE_CONTACTO,
    payload: null,
  }
}
