import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { MainContainer, Row, Col, ContainerFluid } from '@navent-jobs/ui-kit/components/grid'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

import { getEstadosSeleccion, getPostulaciones } from '../../redux/actions/postulaciones'
import { getPostulacionesSortSettings } from '../../redux/actions/sort'

import ListadoPostulaciones from './components/listado-postulaciones'
import Metas from '../../components/metas/mis-postulaciones'
import PageTitle from '../../components/PageTitle'
import CardPlaceholderMobile from './components/card/components/CardPlaceholderMobile'
import CardPlaceholderDesktop from './components/card/components/CardPlaceholderDesktop'
import CardPlaceholderTablet from './components/card/components/CardPlaceholderTablet'
import SearchBar from './components/search-bar'
import EmptyWithButton from '../../components/empty-with-button'
import BannerConsejos from '../../components/banner-consejos'

import {
  SortPostulaciones,
  FiltersContainer,
  FilterWrapper,
  FilterSelect,
  OptionContainer,
  OptionIcon,
  OptionLabel,
  Spacer,
} from './mixins'
import { DataLayersMisPostulaciones } from '../../constants/gtm-events-mis-avisos'
import { IS_ZONA_JOBS } from '../../constants'
import { BannerConsejo, BANNERS_CONSEJOS_POSTULACIONES } from '../../constants/banner-consejos.ts/banners-consejos'
import { getRandomInt } from '../../utils/random-util'

const TOTAL_CARDS_PLACEHOLDER = 10
const KEY_CONSEJOS_POSTULACIONES = 'consejos-postulaciones'

interface OptionProps {
  label: string
  icon: string
  count: number | null
}

const Option = ({ label, icon, count }: OptionProps) => {
  const countWording = count == null ? '' : `(${count})`
  return (
    <OptionContainer>
      <OptionIcon name={icon} size="20" color="rgba(0, 0, 24, 0.6)" />
      <OptionLabel>{`${label} ${countWording}`}</OptionLabel>
    </OptionContainer>
  )
}

const sortItems = [
  { key: 'fechaPostulacion', to: '?fechaPostulacion=true', label: 'Fecha de postulación' },
  { key: 'fechaPublicacionAviso', to: '?fechaPublicacionAviso=true', label: 'Fecha de publicación' },
]

interface Facet {
  id: string
  name: string
  quantity: number
}

const facetNames = {
  RECIBIDO: 'cv_enviados',
  LEIDO: 'cv_leidos',
  CONTACTADO: 'contactados',
  FINALIZADA: 'finalizadas',
  DESCARTADO: 'descartados',
}

const calculateCount = (facetas: Facet[], value: string): number | null => {
  if (!facetas || !facetas.length) {
    return null
  }

  const values = value.split(',').map(v => facetNames[v])

  return facetas
    .filter(facet => values.includes(facet.id))
    .map(facet => facet.quantity)
    .reduce((a, b) => a + b, 0)
}

const CardPlaceholder = () => {
  const { isMobile, isTabletPortrait, isTabletLandscape } = useWindowSize()

  if (isMobile) {
    return <CardPlaceholderMobile />
  }

  if (isTabletPortrait || isTabletLandscape) {
    return <CardPlaceholderTablet />
  }

  return <CardPlaceholderDesktop />
}

const Postulaciones = ({ postulaciones, location, match, loading, facetFilters, success }) => {
  const { t } = useTranslation()

  const facetas = facetFilters.find(f => f.type === 'panelPostulaciones')?.facets

  const postulationStates = [
    {
      value: 'RECIBIDO,LEIDO,CONTACTADO,FINALIZADA,DESCARTADO',
      label: (
        <Option
          label={t('postulaciones.estados.todos')}
          icon="icon-light-list-cards"
          count={calculateCount(facetas, 'RECIBIDO,LEIDO,CONTACTADO,FINALIZADA,DESCARTADO')}
        />
      ),
    },
    {
      value: 'RECIBIDO,LEIDO,CONTACTADO,DESCARTADO',
      label: (
        <Option
          label={t('postulaciones.estados.activos')}
          icon="icon-light-fire"
          count={calculateCount(facetas, 'RECIBIDO,LEIDO,CONTACTADO,DESCARTADO')}
        />
      ),
    },
    {
      value: 'RECIBIDO',
      label: (
        <Option
          label={t('postulaciones.estados.enviado')}
          icon="icon-light-paper-plane"
          count={calculateCount(facetas, 'RECIBIDO')}
        />
      ),
    },
    {
      value: 'LEIDO,DESCARTADO',
      label: (
        <Option
          label={t('postulaciones.estados.leido')}
          icon="icon-light-eye-open"
          count={calculateCount(facetas, 'LEIDO,DESCARTADO')}
        />
      ),
    },
    {
      value: 'CONTACTADO',
      label: (
        <Option
          label={t('postulaciones.estados.contactado')}
          icon="icon-light-message-square"
          count={calculateCount(facetas, 'CONTACTADO')}
        />
      ),
    },
    {
      value: 'FINALIZADA',
      label: (
        <Option
          label={t('postulaciones.estados.finalizado')}
          icon="icon-light-close-circle"
          count={calculateCount(facetas, 'FINALIZADA')}
        />
      ),
    },
  ]
  const dispatch = useDispatch()
  const history = useHistory()
  const [filterApplied, setFilterApplied] = useState(postulationStates[0])
  const [query, setQuery] = useState<string>('')
  const queryParams = new URLSearchParams(location.search)
  const pageParam = Number(new URLSearchParams(location.search).get('page'))
  const currentPage = pageParam === 0 ? 0 : pageParam - 1

  const [bannerConsejo, setBannerConsejo] = useState<BannerConsejo | undefined>(undefined)
  const [changeDate, setChangeDate] = useState(false)

  const closeConsejo = () => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    localStorage.setItem(KEY_CONSEJOS_POSTULACIONES, date.toISOString())
    setChangeDate(true)
  }

  const getListadoPostulaciones = (name, option, pageNumber = 0) => {
    setFilterApplied(option || postulationStates[0])
    dispatch(
      getPostulaciones({
        page: pageNumber,
        filtros: {
          sort: queryParams.get('fechaPublicacionAviso') ? 'fechaPublicacionAviso' : 'fechaPostulacion',
          estado: option?.value ? option.value : postulationStates[0],
          query,
        },
      })
    )
  }

  const changePage = pageNumber => {
    getListadoPostulaciones(null, filterApplied, pageNumber)
  }

  const resetPageParam = () => {
    history.replace({
      search: '',
    })
  }

  const onSubmit = e => {
    e.preventDefault()
    if (Number(currentPage) === 0) {
      changePage(0)
    } else {
      resetPageParam()
    }
  }

  const changeFilters = (name, option) => {
    if (Number(currentPage) === 0) {
      getListadoPostulaciones(name, option)
    } else {
      setFilterApplied(option)
      resetPageParam()
    }
  }

  const goToSearch = () => {
    IS_ZONA_JOBS ? (window.location.href = '/ofertas-de-empleo-argentina.html') : history.push('/empleos.html')
  }

  useEffect(() => {
    changePage(Number(currentPage))
  }, [match.url, location.search])

  useEffect(() => {
    dispatch(getPostulacionesSortSettings())
    dispatch(getEstadosSeleccion())
    DataLayersMisPostulaciones.pageView()
  }, [])

  useEffect(() => {
    if (success) {
      const selected = postulationStates.find(p => p.value === filterApplied.value)
      if (selected) {
        setFilterApplied(selected)
      }
    }
  }, [success])

  useEffect(() => {
    const dateString = localStorage.getItem(KEY_CONSEJOS_POSTULACIONES)
    const today = new Date()

    if (!dateString || new Date(dateString) < today) {
      setBannerConsejo(BANNERS_CONSEJOS_POSTULACIONES)
    } else {
      setBannerConsejo(undefined)
    }
  }, [changeDate])

  return (
    <>
      <Metas />
      <PageTitle iconName="icon-light-checkbox-checked" title="Mis postulaciones" maxWidth={1392}>
        <SearchBar query={query} setQuery={setQuery} onSubmit={onSubmit} />
      </PageTitle>

      <MainContainer>
        {bannerConsejo && (
          <Row>
            <Col>
              <Spacer>
                <BannerConsejos action={closeConsejo} bannerConsejo={bannerConsejo} fullWidth />
              </Spacer>
            </Col>
          </Row>
        )}

        <FiltersContainer>
          <FilterWrapper>
            <FilterSelect
              id="select-postulaciones"
              name="estados"
              onChange={changeFilters}
              fieldOptions={{
                variant: 'lighten',
              }}
              options={postulationStates}
              defaultValue={filterApplied}
              value={filterApplied}
              reactSelectOptions={{
                isClearable: false,
                isSearchable: false,
              }}
            />
          </FilterWrapper>

          <SortPostulaciones
            enabled
            initValue="fechaPostulacion"
            path={location?.pathname}
            queryParams={queryParams}
            items={sortItems}
          />
        </FiltersContainer>

        {!postulaciones && !loading ? (
          <ContainerFluid>
            <Row>
              <Col sm={12} noGutter>
                <EmptyWithButton
                  heading={t('postulaciones.empty.title')}
                  subtitle={t('postulaciones.empty.subtitle')}
                  buttonProps={{ text: t('postulaciones.empty.buttonSubmit'), onClick: goToSearch }}
                />
              </Col>
            </Row>
          </ContainerFluid>
        ) : (
          <Row>
            <Col>
              {loading ? (
                <>
                  {[...Array(TOTAL_CARDS_PLACEHOLDER)].map(index => {
                    return <CardPlaceholder key={index} />
                  })}
                </>
              ) : (
                <ListadoPostulaciones currentPage={currentPage} queryParams={queryParams} />
              )}
            </Col>
          </Row>
        )}
      </MainContainer>
    </>
  )
}

const states = ({ postulacionesStore }) => ({
  postulaciones: postulacionesStore.postulaciones,
  facetFilters: postulacionesStore.filters,
  loading: postulacionesStore.loading,
  success: postulacionesStore.success,
})

export default connect(states)(Postulaciones)
