import { put, call, takeLatest } from 'redux-saga/effects'
import services from '../../services'
import { VALIDATE_RUT, VALIDATE_RUT_ERROR, VALIDATE_RUT_SUCCESS, ValidateRut } from '../types/rut'

function* validateRut({ payload: rutUser }: ValidateRut) {
  try {
    const result = yield call(services.rutValidation.ValidateRut, rutUser)
    if (result?.isValid) {
      yield put({ type: VALIDATE_RUT_SUCCESS, payload: result })
    }
    yield put({ type: VALIDATE_RUT_ERROR, payload: result })
  } catch (error) {
    yield put({ type: VALIDATE_RUT_ERROR, error })
  }
}

/**
 * Watchers
 */
export default function* rut() {
  yield takeLatest<ValidateRut>(VALIDATE_RUT, validateRut)
}
