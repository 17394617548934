import React, { ReactNode, lazy, Suspense, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import { AllowedURL } from '@navent-jobs/ui-kit/components/allowed-url'
import ExecuteIfUserIsLoggedIn from './components/ExecuteIfUserIsLoggedIn'
import { IS_ZONA_JOBS, NOMBRE_SITIO, SITE_ID } from './constants'
import errorPagesSettings from './constants/error-pages-settings'
import features from './constants/features-per-country'

// components
import PageLoader from './components/PageLoader'
import { PrivateRoute } from './components/private-route'
import LayoutAuthHeader from './components/layouts/layout-auth-header'
import LayoutSingleHeader from './components/layouts/layout-single-header'
import { ErrorBoundary } from './components/error-boundary'
import LayoutHeader from './pages/hiring-room/commons/layout-header'

// components login
import WithAutologin from './components/AutoLogin'
import GlobalLoginHandler from './components/GlobalLoginHandler'

// pages
import Home from './pages/home'
import PaymentMP from './pages/payment-mp'
import SignIn from './pages/sign-in'
import SignUp from './pages/sign-up'
import AccountConfirmation from './pages/account-confirmation'
import ChangePasswordEmailSent from './pages/change-password-email-sent'
import ForgetPassword from './pages/forget-password'
import Notifications from './pages/notifications-and-alerts'
import ChangePassword from './pages/change-password'
import MyAccount from './pages/my-account'
import CompanySummary from './pages/companySummary'
import Postulaciones from './pages/postulaciones'
import PostulacionesStatistics from './pages/postulaciones-statistics'
import MarketingUTM from './components/MarketingUTM'
import LandingNulinga from './pages/product-landing/nulinga'
import LandingMiguru from './pages/product-landing/miguru'
import LandingDoctorcv from './pages/product-landing/doctorcv'
import LandingPda from './pages/product-landing/pda'
import ListadoEmpresas from './pages/listado-empresas'
import Products from './pages/products'
import Preview from './pages/products-preview'
import CalificarEmpresa from './pages/calificar-empresas'
import PageFAQ from './pages/faq'
import LandingCursoNulinga from './pages/product-landing/nulingaCourse'
import RegistroActividad from './components/RegistroActividad'
import Logout from './pages/logout'
import Snackbar from './components/snackbar'
import PromediosSalariales from './pages/promedio-salarial'
import PromedioSalarialArea from './pages/promedio-salarial/area'
import SavePage from './pages/save-page'
import LandingSalario from './pages/product-landing/salario'
import { getFeria } from './redux/actions/feria'
import { TipoFeria } from './redux/types/feria'

// lazyload
const ListadoAvisos = lazy(() => import(/* webpackChunkName: "listadoAvisos" */ './pages/listado-avisos'))
const ListadoAvisosBis = lazy(() => import(/* webpackChunkName: "listadoAvisos" */ './pages/listado-avisos-bis'))
const FichaAviso = lazy(() => import(/* webpackChunkName: "fichaAviso" */ './pages/ficha-aviso'))
const Semana = lazy(() => import(/* webpackChunkName: "semanas" */ './pages/semanas-especiales'))
const TestDePersonalidad = lazy(
  () => import(/* webpackChunkName: "testDePersonalidad" */ './pages/test-de-personalidad')
)
const ErrorPage = lazy(() => import(/* webpackChunkName: "errorPage" */ '@navent-jobs/ui-kit/components/errorPage'))
const Perfil = lazy(() => import(/* webpackChunkName: "perfil" */ './pages/candidato/perfil'))
const Curriculum = lazy(() => import(/* webpackChunkName: "curriculum" */ './pages/candidato/curriculum'))
const Marketplace = lazy(() => import(/* webpackChunkName: "curriculum" */ './pages/marketplace'))
const Onboarding = lazy(() => import(/* webpackChunkName: "onboarding" */ './pages/candidato/curriculum/onboarding'))
const Messages = lazy(() => import(/* webpackChunkName: "onboarding" */ './pages/messages'))
const PostulacionQR = lazy(() => import(/* webpackChunkName: "postulacion-qr" */ './pages/postulacion-qr'))
const Terminos = lazy(() => import(/* webpackChunkName: "terminos" */ './pages/static/terminos'))
const PoliticasCookies = lazy(() => import(/* webpackChunkName: "terminos" */ './pages/static/politica-cookies'))
const PoliticasCalidad = lazy(() => import(/* webpackChunkName: "terminos" */ './pages/static/politicas-calidad'))
const PoliticasPrivacidad = lazy(() => import(/* webpackChunkName: "terminos" */ './pages/static/politicas-privacidad'))
const CondicionesContratacion = lazy(
  () => import(/* webpackChunkName: "terminos" */ './pages/static/condiciones-contratacion')
)
const ConfirmReference = lazy(() => import(/* webpackChunkName: "terminos" */ './pages/confirm-reference'))
const MisPostulaciones = lazy(() => import(/* webpackChunkName: "salarioPretendido" */ './pages/mis-postulaciones'))
const HomeFeria = lazy(() => import(/* webpackChunkName: "homeFeria" */ './pages/feria/home'))
const FichaEmpresaFeria = lazy(() => import(/* webpackChunkName: "fichaEmpresaFeria" */ './pages/feria/ficha-empresa'))
const ConferenciasFeria = lazy(() => import(/* webpackChunkName: "conferenciasFeria" */ './pages/feria/conferencias'))

interface RouteWithErrorBoundaryProps {
  allowAutologin?: boolean
  exact?: boolean
  component: ReactNode
  nombreSitio?: string
  status?: number
  path: string | string[]
}

const RouteWithErrorBoundary = (props: RouteWithErrorBoundaryProps) => {
  return (
    <ErrorBoundary {...props}>
      <Route {...props} />
    </ErrorBoundary>
  )
}

const ErrorRoutes = () => {
  return (
    <Switch>
      <Redirect from="*" to="/404" />
    </Switch>
  )
}

const FeriasRoutes = ({ feria, feriaStates }) => {
  const dispatch = useDispatch()
  const paths = window.location.pathname?.split('/')
  const url = paths[1]
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Fix retrocompatibilidad, se eliminará
    if (url === 'feria' && paths.length === 4) {
      dispatch(getFeria(paths[2]))
    } else {
      dispatch(getFeria(url))
    }
  }, [])

  useEffect(() => {
    if (feriaStates.success || feriaStates.error) {
      setLoading(false)
    }
  }, [feriaStates])

  if (loading) {
    return <PageLoader />
  }

  if (feriaStates.error) {
    return <Redirect from={url} to="/404" />
  }

  if (!feria) {
    return <Redirect from={url} to="/" />
  }

  return (
    <>
      <RouteWithErrorBoundary
        exact
        path={[`/feria/${feria.slug}/empresa_:empresa_:id.html`]}
        component={() => <Redirect from={url} to={window.location.pathname.replace('/feria', '')} />}
      />

      <RouteWithErrorBoundary
        exact
        path={`/${feria.slug}`}
        component={props => (
          <LayoutAuthHeader {...props} singleHeader>
            <Suspense fallback={<PageLoader />}>
              <HomeFeria {...props} />
            </Suspense>
          </LayoutAuthHeader>
        )}
      />

      <RouteWithErrorBoundary
        exact
        path={[`/${feria.slug}/empresa_:empresa_:id.html`]}
        component={props => (
          <LayoutAuthHeader
            {...props}
            hideWithPath={[`/${feria.slug}/empresa_:empresa_:id.html`]}
            hideBottomNavbar
            singleHeader
          >
            <Suspense fallback={<PageLoader />}>
              <FichaEmpresaFeria {...props} />
            </Suspense>
          </LayoutAuthHeader>
        )}
      />

      {feria.tipo === TipoFeria.PAIS && (
        <RouteWithErrorBoundary
          exact
          path={`/${feria.slug}/multimedia`}
          component={props => (
            <LayoutAuthHeader {...props} singleHeader>
              <Suspense fallback={<PageLoader />}>
                <ConferenciasFeria {...props} />
              </Suspense>
            </LayoutAuthHeader>
          )}
        />
      )}
    </>
  )
}

const provinciasRegex =
  '(buenos-aires|santa-fe|cordoba|mendoza|neuquen|catamarca|chubut|chaco|entre-rios|san-luis|corrientes|rio-negro|salta|san-juan|santa-cruz|jujuy|misiones|tierra-del-fuego|la-pampa|tucuman|formosa|la-rioja)'

const Root = ({ feria, feriaStates }) => {
  const FICHA_AVISO_PAGE_PATH = '/empleos/(.*)-:idAviso.html*'
  const COMPANY_SUMMARY_PAGE_PATH = ['/perfiles/:empresa_:id', '/perfil-empresa/:empresa_:id']
  const LISTA_AVISOS_GUARDADOS = features[SITE_ID || 'BMAR'].saveJobPosting.enabled
  const LANDING_NULINGA = features[SITE_ID || 'BMAR'].products?.nulinga?.landing
  const LANDING_MIGURU = features[SITE_ID || 'BMAR'].products?.lablab?.landing
  const PRODUCTOS_BUMERAN = features[SITE_ID || 'BMAR'].marketplace.enabled
  const LANDING_DOCTORCV = features[SITE_ID || 'BMAR'].products?.doctorcv?.landing
  const LANDING_PDA = features[SITE_ID || 'BMAR'].products?.pda?.landing
  const LANDING_NULINGA_COURSE = features[SITE_ID || 'BMAR'].products?.nulingaCourse?.landing
  const PROMEDIO_SALARIAL = features[SITE_ID || 'BMAR'].promedioSalarial?.enabled
  const LANDING_SALARIO = features[SITE_ID || 'BMAR'].products?.salario?.landing

  return (
    <Router>
      <ExecuteIfUserIsLoggedIn />
      <MarketingUTM />
      <GlobalLoginHandler />
      <RegistroActividad />
      <Snackbar />
      <Switch>
        <RouteWithErrorBoundary exact path="/linkedin" component={LinkedInPopUp} />
        <RouteWithErrorBoundary exact path="/logout" component={Logout} />
        <RouteWithErrorBoundary
          exact
          path={FICHA_AVISO_PAGE_PATH}
          component={props => (
            <WithAutologin>
              <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} hideBottomNavbar {...props}>
                <Suspense fallback={<PageLoader />}>
                  <FichaAviso {...props} />
                </Suspense>
              </LayoutAuthHeader>
            </WithAutologin>
          )}
          nombreSitio={NOMBRE_SITIO}
        />
        <RouteWithErrorBoundary
          exact
          path="/empleos/:idAviso.html*"
          component={props => (
            <WithAutologin>
              <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} hideBottomNavbar {...props}>
                <Suspense fallback={<PageLoader />}>
                  <FichaAviso {...props} />
                </Suspense>
              </LayoutAuthHeader>
            </WithAutologin>
          )}
          nombreSitio={NOMBRE_SITIO}
        />
        <RouteWithErrorBoundary
          exact
          path="/empleos/:idAviso"
          component={props => (
            <WithAutologin>
              <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} hideBottomNavbar {...props}>
                <Suspense fallback={<PageLoader />}>
                  <FichaAviso {...props} />
                </Suspense>
              </LayoutAuthHeader>
            </WithAutologin>
          )}
          nombreSitio={NOMBRE_SITIO}
        />
        {LANDING_NULINGA && (
          <RouteWithErrorBoundary
            path="/postulantes/productos/nulinga"
            component={props => (
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <LandingNulinga {...props} />
                </Suspense>
              </LayoutAuthHeader>
            )}
          />
        )}
        {LANDING_MIGURU && (
          <RouteWithErrorBoundary
            path="/postulantes/productos/miguru/preview"
            component={props => <Preview {...props} productName="MIGURU" />}
          />
        )}
        {LANDING_MIGURU && (
          <RouteWithErrorBoundary
            path="/postulantes/productos/miguru"
            component={props => (
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <LandingMiguru {...props} />
                </Suspense>
              </LayoutAuthHeader>
            )}
          />
        )}
        {LANDING_DOCTORCV && (
          <RouteWithErrorBoundary
            path="/postulantes/productos/doctorcv"
            component={props => (
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <LandingDoctorcv {...props} />
                </Suspense>
              </LayoutAuthHeader>
            )}
          />
        )}
        {LANDING_PDA && (
          <RouteWithErrorBoundary
            path="/postulantes/productos/pda"
            component={props => (
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <LandingPda {...props} />
                </Suspense>
              </LayoutAuthHeader>
            )}
          />
        )}
        {LANDING_NULINGA_COURSE && (
          <RouteWithErrorBoundary
            path="/postulantes/productos/curso-nulinga"
            component={props => (
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <LandingCursoNulinga {...props} />
                </Suspense>
              </LayoutAuthHeader>
            )}
          />
        )}
        {LANDING_SALARIO && (
          <RouteWithErrorBoundary
            path="/conoce-mas/brutovsneto"
            component={props => (
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <LandingSalario {...props} />
                </Suspense>
              </LayoutAuthHeader>
            )}
          />
        )}
        {PRODUCTOS_BUMERAN && (
          <RouteWithErrorBoundary
            exact
            path="/postulantes/productos"
            component={props => (
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <Products {...props} />
                </Suspense>
              </LayoutAuthHeader>
            )}
          />
        )}
        <RouteWithErrorBoundary
          path={[
            '/empleos*.html',
            '/en-:provincia/empleos*.html',
            '/en-:provincia/:localidad/empleos*.html',
            `/:localidad-:provincia${provinciasRegex}/ofertas-de-empleo.html`,
            `/:localidad-:provincia${provinciasRegex}/ofertas-de-empleo:filtros-trabajo:masFiltros.html`,
            `/:localidad-:provincia${provinciasRegex}/ofertas-de-:empleo(empleo|trabajo):filtros.html`,
            `/:provincia${provinciasRegex}/ofertas-de-empleo.html`,
            `/:provincia${provinciasRegex}/ofertas-de-empleo:filtros-trabajo:masFiltros.html`,
            `/:provincia${provinciasRegex}/ofertas-de-:empleo(empleo|trabajo):filtros.html`,
            `/ofertas-de-empleo.html`,
            `/ofertas-de-empleo:filtros-trabajo:masFiltros.html`,
            `/ofertas-de-:empleo(empleo|trabajo):filtros.html`,
          ]}
          component={props => (
            <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} {...props}>
              <Suspense fallback={<PageLoader />}>
                <ListadoAvisos {...props} />
              </Suspense>
            </LayoutAuthHeader>
          )}
        />
        {/* <RouteWithErrorBoundary
          path={[
            '/listado/empleos*.html',
            '/listado/en-:provincia/empleos*.html',
            '/listado/en-:provincia/:localidad/empleos*.html',
          ]}
          component={props => <ListadoAvisosBis {...props} />}
        /> */}
        <RouteWithErrorBoundary path="/listado-empresas" component={props => <ListadoEmpresas {...props} />} />
        <RouteWithErrorBoundary
          exact
          path={['/login', '/postulantes/signin']}
          component={props => <SignIn {...props} />}
        />
        <RouteWithErrorBoundary
          exact
          path={['/signin', '/postulantes/signup']}
          component={props => <SignUp {...props} />}
        />
        <RouteWithErrorBoundary
          exact
          path="/postulantes/recuperar-contrasena"
          component={props => <ForgetPassword {...props} />}
        />
        <RouteWithErrorBoundary
          exact
          path="/postulantes/recuperar-contrasena/email-enviado"
          component={props => <ChangePasswordEmailSent {...props} />}
        />
        <RouteWithErrorBoundary
          path={['/cambioPassword/nuevaPassword', '/postulantes/cambiar-contrasena']}
          component={props => <ChangePassword {...props} />}
        />
        <RouteWithErrorBoundary exact path="/" component={props => <Home {...props} />} />
        <RouteWithErrorBoundary path="/postulantes/cuenta" component={props => <MyAccount {...props} />} />
        <RouteWithErrorBoundary
          path="/postulantes/notificaciones-y-alertas"
          component={props => <Notifications {...props} />}
        />
        <RouteWithErrorBoundary path="/postulantes/preguntas-frecuentes" component={props => <PageFAQ {...props} />} />

        {IS_ZONA_JOBS && (
          <RouteWithErrorBoundary
            path="/postulantes/perfil"
            component={props => (
              <PrivateRoute>
                <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} {...props}>
                  <Suspense fallback={<PageLoader />}>
                    <Perfil {...props} />
                  </Suspense>
                </LayoutAuthHeader>
              </PrivateRoute>
            )}
          />
        )}

        {!IS_ZONA_JOBS && <RouteWithErrorBoundary path="/postulantes/perfil" component={() => <Redirect to="/" />} />}

        <RouteWithErrorBoundary
          path={['/postulantes/mis-postulaciones']}
          component={props => <MisPostulaciones {...props} />}
        />

        <RouteWithErrorBoundary
          path={['/candidatos/curriculum', '/postulantes/curriculum']}
          component={props => (
            <PrivateRoute>
              <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} {...props}>
                <Suspense fallback={<PageLoader />}>
                  <Curriculum {...props} />
                </Suspense>
              </LayoutAuthHeader>
            </PrivateRoute>
          )}
        />

        <RouteWithErrorBoundary
          path={['/postulantes/minimarketplace']}
          component={props => <Marketplace {...props} />}
        />
        <RouteWithErrorBoundary
          exact
          path="/perfiles/:id?/calificacion"
          component={props => (
            <PrivateRoute>
              <CalificarEmpresa {...props} />
            </PrivateRoute>
          )}
        />
        <RouteWithErrorBoundary
          path={COMPANY_SUMMARY_PAGE_PATH}
          component={props => (
            <LayoutAuthHeader hideWithPath={[COMPANY_SUMMARY_PAGE_PATH]} hideBottomNavbar {...props}>
              <Suspense fallback={<PageLoader />}>
                <CompanySummary {...props} />
              </Suspense>
            </LayoutAuthHeader>
          )}
        />
        <RouteWithErrorBoundary
          path="/bienvenida"
          component={props => (
            <PrivateRoute>
              <Suspense fallback={<PageLoader />}>
                <Onboarding {...props} tipo="bienvenida" />
              </Suspense>
            </PrivateRoute>
          )}
        />
        <RouteWithErrorBoundary
          path="/actualizarcv"
          component={props => (
            <PrivateRoute>
              <Suspense fallback={<PageLoader />}>
                <Onboarding {...props} tipo="reemplazo" />
              </Suspense>
            </PrivateRoute>
          )}
        />
        <RouteWithErrorBoundary
          path={[
            '/postulantes/mensajes/postulacion/:postulacionId',
            '/postulantes/mensajes/:mensajeId',
            '/postulantes/mensajes',
          ]}
          component={props => (
            <PrivateRoute>
              <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} {...props}>
                <Suspense fallback={<PageLoader />}>
                  <Messages {...props} />
                </Suspense>
              </LayoutAuthHeader>
            </PrivateRoute>
          )}
        />
        <RouteWithErrorBoundary
          exact
          path="/test-de-personalidad"
          component={props => (
            <AllowedURL feature="pda" features={features} siteId={SITE_ID}>
              <PrivateRoute>
                <LayoutAuthHeader hideWithPatch={[FICHA_AVISO_PAGE_PATH]} {...props}>
                  <Suspense fallback={<PageLoader />}>
                    <TestDePersonalidad />
                  </Suspense>
                </LayoutAuthHeader>
              </PrivateRoute>
            </AllowedURL>
          )}
        />
        <RouteWithErrorBoundary
          exact
          path="/semana/:nombreSemana"
          component={props => (
            <LayoutSingleHeader showBackButton={false}>
              <Suspense fallback={<PageLoader />}>
                <Semana {...props} />
              </Suspense>
            </LayoutSingleHeader>
          )}
        />
        <RouteWithErrorBoundary
          exact
          path="/payment-mp/:status/:operationId"
          component={props => <PaymentMP {...props} />}
        />
        <RouteWithErrorBoundary
          exact
          path={['/candidatos/postulaciones', '/postulantes/postulaciones']}
          component={props => (
            <PrivateRoute>
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <Postulaciones {...props} />
                </Suspense>
              </LayoutAuthHeader>
            </PrivateRoute>
          )}
        />
        <RouteWithErrorBoundary
          exact
          path="/postulantes/postulaciones/estadisticas-:idPostulacion"
          component={props => <PostulacionesStatistics {...props} />}
        />
        <RouteWithErrorBoundary
          exact
          path={['/registro/confirmarCuenta', '/postulantes/registro/confirmar-cuenta']}
          component={props => (
            <WithAutologin>
              <AccountConfirmation {...props} />
            </WithAutologin>
          )}
        />
        {LISTA_AVISOS_GUARDADOS && (
          <RouteWithErrorBoundary
            exact
            path="/postulantes/avisos-guardados"
            component={props => (
              <PrivateRoute>
                <LayoutAuthHeader {...props}>
                  <Suspense fallback={<PageLoader />}>
                    <SavePage {...props} />
                  </Suspense>
                </LayoutAuthHeader>
              </PrivateRoute>
            )}
          />
        )}
        <RouteWithErrorBoundary
          path="/postulantes/postulacion-qr/:idEmpresa/:idAviso"
          component={props => (
            <PrivateRoute>
              <Suspense fallback={<PageLoader />}>
                <PostulacionQR {...props} />
              </Suspense>
            </PrivateRoute>
          )}
        />

        <RouteWithErrorBoundary
          exact
          path="/terminos-y-condiciones"
          component={props => (
            <LayoutAuthHeader {...props}>
              <Suspense fallback={<PageLoader />}>
                <Terminos {...props} />
              </Suspense>
            </LayoutAuthHeader>
          )}
        />
        {SITE_ID === 'BMEC' && (
          <RouteWithErrorBoundary
            exact
            path="/politica-de-cookies"
            component={props => (
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <PoliticasCookies {...props} />
                </Suspense>
              </LayoutAuthHeader>
            )}
          />
        )}

        <RouteWithErrorBoundary
          exact
          path="/politica-gestion-calidad"
          component={props => (
            <LayoutAuthHeader {...props}>
              <Suspense fallback={<PageLoader />}>
                <PoliticasCalidad {...props} />
              </Suspense>
            </LayoutAuthHeader>
          )}
        />

        <RouteWithErrorBoundary
          exact
          path="/politica-de-privacidad"
          component={props => (
            <LayoutAuthHeader {...props}>
              <Suspense fallback={<PageLoader />}>
                <PoliticasPrivacidad {...props} />
              </Suspense>
            </LayoutAuthHeader>
          )}
        />

        <RouteWithErrorBoundary
          exact
          path="/condiciones-de-contratacion"
          component={props => (
            <LayoutAuthHeader {...props}>
              <Suspense fallback={<PageLoader />}>
                <CondicionesContratacion {...props} />
              </Suspense>
            </LayoutAuthHeader>
          )}
        />

        <RouteWithErrorBoundary
          path={['/confirmar-referencia/:postulanteId/:referenciaId']}
          component={props => (
            <Suspense fallback={<PageLoader />}>
              <ConfirmReference {...props} />
            </Suspense>
          )}
        />

        {PROMEDIO_SALARIAL && (
          <RouteWithErrorBoundary
            exact
            path="/salarios"
            component={props => (
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <PromediosSalariales {...props} />
                </Suspense>
              </LayoutAuthHeader>
            )}
          />
        )}

        {PROMEDIO_SALARIAL && (
          <RouteWithErrorBoundary
            exact
            path="/salarios/:area"
            component={props => (
              <LayoutAuthHeader {...props}>
                <Suspense fallback={<PageLoader />}>
                  <PromedioSalarialArea {...props} />
                </Suspense>
              </LayoutAuthHeader>
            )}
          />
        )}

        <RouteWithErrorBoundary
          exact
          path="/404"
          status={404}
          component={props => (
            <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} {...props}>
              <Suspense fallback={<PageLoader />}>
                <ErrorPage statusCode="404" setting={errorPagesSettings} />
              </Suspense>
            </LayoutAuthHeader>
          )}
        />

        <RouteWithErrorBoundary
          exact
          path="/500"
          status={500}
          component={props => (
            <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} {...props}>
              <Suspense fallback={<PageLoader />}>
                <ErrorPage statusCode="500" setting={errorPagesSettings} />
              </Suspense>
            </LayoutAuthHeader>
          )}
        />

        <RouteWithErrorBoundary
          exact
          path="/503"
          status={503}
          component={props => (
            <LayoutAuthHeader hideWithPath={[FICHA_AVISO_PAGE_PATH]} {...props}>
              <Suspense fallback={<PageLoader />}>
                <ErrorPage statusCode="503" setting={errorPagesSettings} />
              </Suspense>
            </LayoutAuthHeader>
          )}
        />

        {!IS_ZONA_JOBS && <FeriasRoutes feria={feria} feriaStates={feriaStates} />}

        <ErrorRoutes />
      </Switch>
    </Router>
  )
}

const states = ({ feriaStore }) => ({
  feria: feriaStore.feria,
  feriaStates: feriaStore.feriaStates,
})

export default connect(states)(Root)
