import {
  VALIDATE_RUT,
  VALIDATE_RUT_RESET,
  VALIDATE_RUT_SUCCESS,
  VALIDATE_RUT_ERROR,
  initialState,
  RutActions,
  RutState,
} from '../types/rut'

/**
 * Action es el valor devuelto por el action
 * action.payload será el valor que quiero añadir, borrar, etc...
 */
const reducers = (state = initialState, action: RutActions): RutState => {
  switch (action.type) {
    case VALIDATE_RUT:
      return {
        ...state,
        response: null,
        loading: true,
        success: false,
        error: false,
      }
    case VALIDATE_RUT_RESET:
      return {
        ...state,
        response: null,
        loading: false,
        success: false,
        error: false,
      }
    case VALIDATE_RUT_SUCCESS:
      return {
        ...state,
        response: action.payload,
        loading: false,
        success: true,
        error: false,
      }
    case VALIDATE_RUT_ERROR:
      return {
        ...state,
        response: null,
        loading: false,
        success: false,
        error: true,
      }
    default:
      return state
  }
}

export default reducers
