import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { LoaderContainer } from './mixins'

const CardPlaceholderMobile = props => {
  // obtenemos el ancho de LoaderContainer
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const widthWithMargin = wrapperWidth - 32
  return (
    <LoaderContainer ref={node => node && setWrapperWidth(node.offsetWidth)}>
      <ContentLoader
        speed={2}
        width={wrapperWidth}
        height={276}
        viewBox="0 0 100% 100%"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <path d="M 0 8 a 8 8 0 18 -8 h 312 a 8 8 0 18 8 v 312 a 8 8 0 1 -8 8 H 8 a 8 8 0 1 -8 -8 V 8 z" />
        <path d="M 0 0 h 6 v 328 H 0 z" />

        <rect x="16" y="16" rx="2" ry="2" width={widthWithMargin} height="24" />

        <rect x="16" y="52" rx="2" ry="2" width={widthWithMargin * 0.5} height="14" />

        <rect x="16" y="76" rx="4" ry="4" width="68" height="44" />
        <rect x="96" y="78" rx="2" ry="2" width={widthWithMargin * 0.6} height="18" />
        <rect x="96" y="104" rx="2" ry="2" width={widthWithMargin * 0.5} height="14" />

        <rect x="16" y="132" rx="2" ry="2" width={widthWithMargin} height="14" />
        <rect x="16" y="152" rx="2" ry="2" width={widthWithMargin} height="14" />

        <rect x="16" y="184" rx="4" ry="4" width="20" height="20" />
        <rect x="40" y="184" rx="2" ry="2" width="144" height="20" />
        <rect x="16" y="208" rx="4" ry="4" width="20" height="20" />
        <rect x="40" y="208" rx="2" ry="2" width="90" height="20" />

        <rect x={widthWithMargin - 86} y="240" rx="4" ry="4" width="102" height="24" />
      </ContentLoader>
    </LoaderContainer>
  )
}
//58

export default CardPlaceholderMobile
