import styled from 'styled-components'

export const CorazonContainer = styled('div')``

export const Container = styled('div')`
  display: flex;
  width: fit-content;
  cursor: pointer;
  align-items: center;
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`
