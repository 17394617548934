import { Request, Get, Patch, Post, Put, Delete } from '@navent-jobs/config'
import { isEmpty } from 'lodash'
import {
  ReferenciaEstudio,
  UpdateDatosContactoType,
  UpdateDatosPersonales,
  AddOrUpdateEstudioType,
  UpdateIdiomaType,
  AdjuntoType,
  ArchivoAdjuntoDiscapacidad,
  UpdateSkillsType,
  DeleteSkillType,
  ReferenciaLaboral,
  GetArchivoAdjuntoDiscapacidad,
  GetArchivoCvAdjunto,
  UpdateExperienciaType,
  UpdateDatosRegistro,
  UpdateDocumentoPayload,
  PrimerEmpleoType,
  AddIdiomasType,
  CertificadoEstudio,
  GetArchivoCertificadoAdjunto,
  SinEstudioEstudioType,
  SalarioType,
  ObjetivoLaboralType,
  ReplaceExperienciasType,
  UpdateCanalesDeContactoType,
} from '../redux/types/applicant/commons'
import { CandidateAccountConfirmation } from '../redux/types/applicant/confirm-candidate'
import { UpdateDatosPersonalesOnbordingType } from '../redux/types/applicant/curriculum/datos-personales-onbording'
import { ChangePasswordRequestDto, ChangePasswordSuccessDto } from '../redux/types/change-password'
import { getCookieData } from '../utils/cookies'

export class ErrorApplicant extends Error {}

class Applicant {
  public async GetDatosPersonales() {
    const request = new Request()
    request.path = 'api/candidates/datosPostulante'
    request.method = Get
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorApplicant(`Usuario no encontrado`)
        }
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorApplicant(`Usuario no encontrado`)
        }
      })
  }

  public async GetApplicantSummary() {
    const request = new Request()
    request.path = 'api/candidates/datosPostulanteResumido'
    request.method = Get
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorApplicant(`Usuario no encontrado`)
        }
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorApplicant(`Usuario no encontrado`)
        }
      })
  }

  public async GetApplicant() {
    const request = new Request()
    request.path = 'api/curriculum/datosPostulanteTargeting'
    request.method = Get
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorApplicant(`Usuario no encontrado`)
        }

        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorApplicant(`Usuario no encontrado`)
        }
      })
  }

  public async GetCurriculum() {
    const request = new Request()
    request.path = 'api/curriculum/me'
    request.method = Get
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorApplicant(`Curriculum no encontrado`)
        }

        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorApplicant(`Curriculum no encontrado`)
        }
      })
  }

  public UpdateCanalesDeContanto = (data: UpdateCanalesDeContactoType) => {
    const request = new Request()
    request.method = Patch
    request.path = `/api/candidates/datosPersonales`
    request.body = data
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar canales de contacto`)
    })
  }

  public UpdateDatosPersonles = (datos: UpdateDatosPersonales) => {
    const request = new Request()
    request.method = Patch
    request.path = `/api/candidates/datosPersonales`
    request.body = datos
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar datos personales`)
    })
  }

  public UpdateDatosPersonlesOnbording = (datos: UpdateDatosPersonalesOnbordingType) => {
    const request = new Request()
    request.method = Patch
    request.path = `/api/candidates/datosPersonales`
    request.body = datos
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar datos personales onbording`)
    })
  }

  public UpdateDatosRegistro = (datos: UpdateDatosRegistro) => {
    const request = new Request()
    request.method = Patch
    request.path = `/api/candidates/datosPersonales`
    request.body = datos
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar datos personales post registro`)
    })
  }

  /** Castea imagen Base64 to File */
  private dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
    const res: Response = await fetch(dataUrl)
    const blob: Blob = await res.blob()
    return new File([blob], fileName, { type: 'image/png' })
  }

  public putSuscripciones = async suscripcionId => {
    let response = {}
    try {
      const request = new Request()
      request.path = `/api/ajustes/suscripciones/${suscripcionId}`
      request.method = Put
      request.body = {}
      response = await request.call()
    } catch (error) {
      throw new ErrorApplicant('Error request ')
    }
    return response
  }

  public emailVerify = async email => {
    return new Promise(async (resolve, reject) => {
      try {
        const request = new Request()
        request.path = `/api/ajustes/cuenta/validate-email/${email}`
        request.method = Get
        const response = await request.call()
        if (response && response.inUse) {
          return resolve(false)
        }
        return resolve(true)
      } catch (e) {
        return reject()
      }
    })
  }

  public DeleteImageProfile = () => {
    const request = new Request()
    request.method = Delete
    request.path = 'api/load-foto/deleteFoto'
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar la foto de perfil`)
    })
  }

  public UpdateImageProfile = async img => {
    const blobImage = await this.dataUrlToFile(img, 'image')
    const formData = new FormData()
    formData.append('file', blobImage, blobImage.name)

    const request = new Request()
    request.method = Post
    request.path = `api/load-foto`
    request.body = formData
    request.headers = {
      'Content-Type': 'multipart/form-data',
    }
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar la foto de perfil`)
    })
  }

  public UpdateImageProfileBase64 = img => {
    const imageBase64 = /base64,(.+)/.exec(img)
    const request = new Request()
    request.method = Post
    request.path = `api/load-foto/base64`
    request.body = { file: imageBase64 !== null && imageBase64[1] }
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar la foto de perfil`)
    })
  }

  public UpdateDatosContacto = (datos: UpdateDatosContactoType) => {
    const request = new Request()
    request.method = Patch
    request.path = `/api/candidates/datosPersonales`
    request.body = datos
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar datos de contacto`)
    })
  }

  public DeleteEstudio = (estudioId: string) => {
    const request = new Request()
    request.method = Delete
    request.path = `/api/curriculum/estudios/${estudioId}`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar el estudio`)
    })
  }

  public DeleteReferenciaEstudio = (referenciaId: string) => {
    const request = new Request()
    request.method = Delete
    request.path = `/api/curriculum/referenciaEstudio/${referenciaId}`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar la referencia de estudio`)
    })
  }

  public AddOrUpdateEstudio = (datos: AddOrUpdateEstudioType) => {
    const { estudio, estudioId, origen } = datos

    if (!estudioId) {
      // Agrega estudio
      const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
      const request = new Request()
      request.method = Post
      request.path = this.createUrl(`/api/curriculum/estudios`, { utmSource, origen })
      request.body = estudio
      return request.call().catch(() => {
        throw new ErrorApplicant(`No se pudo agregar estudio`)
      })
    }

    // Actualiza estudio
    const request = new Request()
    request.method = Put
    request.path = `/api/curriculum/estudios/${estudioId}`
    request.body = estudio
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar estudio`)
    })
  }

  public AddEstudios = (estudios: AddOrUpdateEstudioType['estudio'][]) => {
    const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
    const request = new Request()
    request.method = Put
    request.path = this.createUrl(`/api/curriculum/estudios/bulk`, { utmSource })
    request.body = estudios
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo agregar estudios`)
    })
  }

  public UpsertReferenciaLaboral = (datos: ReferenciaLaboral) => {
    if (datos.id) {
      return this.UpdtaeReferenciaLaboral(datos)
    }
    return this.AddReferenciaLaboral(datos)
  }

  public AddReferenciaLaboral = (datos: ReferenciaLaboral) => {
    const request = new Request()
    request.method = Post
    request.path = `/api/curriculum/referenciaLaboral`
    request.body = datos
    delete request.body.id
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo crear la referencia a estudio`)
    })
  }

  public UpdtaeReferenciaLaboral = (datos: ReferenciaLaboral) => {
    const request = new Request()
    request.method = Put
    request.path = `/api/curriculum/referenciaLaboral/${datos.id}`
    request.body = datos
    delete request.body.id
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar la referencia a estudio`)
    })
  }

  public UpsertReferenciaEstudio = (datos: ReferenciaEstudio) => {
    if (datos.id) {
      return this.UpdtaeReferenciaEstudio(datos)
    }
    return this.AddReferenciaEstudio(datos)
  }

  public AddReferenciaEstudio = (datos: ReferenciaEstudio) => {
    const request = new Request()
    request.method = Post
    request.path = `/api/curriculum/referenciaEstudio`
    request.body = datos
    delete request.body.id
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo crear la referencia a estudio`)
    })
  }

  public UpdtaeReferenciaEstudio = (datos: ReferenciaEstudio) => {
    const request = new Request()
    request.method = Put
    request.path = `/api/curriculum/referenciaEstudio/${datos.id}`
    request.body = datos
    delete request.body.id
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar la referencia a estudio`)
    })
  }

  public AddCertificadoEstudio = (datos: CertificadoEstudio) => {
    const { estudioId } = datos
    const formData = new FormData()
    if (datos?.file) {
      formData.append('file', datos.file, datos.file.name)
    }
    const request = new Request()
    request.method = Post
    request.path = `/api/curriculum/certificadoEstudio/${estudioId}`
    if (datos?.url) {
      request.body = {
        link: datos?.url,
      }
    }
    if (datos?.file) {
      request.body = formData
      request.headers = {
        'Content-Type': 'multipart/form-data',
      }
    }
    delete request.body.id
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo crear el certificado de estudio`)
    })
  }

  public DeleteCertificadoEstudio = (estudioId: string) => {
    const request = new Request()
    request.method = Delete
    request.path = `/api/curriculum/certificadoEstudio/${estudioId}`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar el certificado de estudio`)
    })
  }

  public UpsertIdioma = (datos: UpdateIdiomaType) => {
    if (datos.operationType === 'modificacion') {
      return this.UpdateIdioma(datos)
    }
    return this.AddIdioma(datos)
  }

  public AddIdioma = (datos: UpdateIdiomaType) => {
    const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
    const { origen } = datos
    const request = new Request()
    request.method = Post
    request.path = this.createUrl(`/api/curriculum/idioma/${datos.idIdioma}`, { utmSource, origen })
    request.body = {
      nivelEscritoId: datos.nivelEscrito,
      nivelOralId: datos.nivelOral,
    }
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo agregar el idioma`)
    })
  }

  public UpdateIdioma = (datos: UpdateIdiomaType) => {
    const request = new Request()
    request.method = Put
    request.path = `/api/curriculum/idioma/${datos.idIdioma}`
    request.body = {
      nivelEscritoId: datos.nivelEscrito,
      nivelOralId: datos.nivelOral,
    }

    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar el idioma`)
    })
  }

  public AddIdiomas = (datos: AddIdiomasType[]) => {
    const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
    const request = new Request()
    request.method = Put
    request.path = this.createUrl(`/api/curriculum/idiomas`, { utmSource })
    request.body = datos
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo agregar los idiomas`)
    })
  }

  public DeleteIdioma = (idIdioma: string) => {
    const request = new Request()
    request.method = Delete
    request.path = `/api/curriculum/idioma/${idIdioma}`

    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar el idioma`)
    })
  }

  public DeleteDiscapacidad = () => {
    const request = new Request()
    request.method = Patch
    request.path = `/api/candidates/datosPersonales`
    request.body = {
      discapacidadDetalle: null,
    }
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar la discapacidad`)
    })
  }

  private AddDiscapacidadDescription = (description: string) => {
    const request = new Request()
    request.method = Patch
    request.path = `/api/candidates/datosPersonales`
    request.body = {
      discapacidadDetalle: description,
    }
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo agregar la discapacidad description`)
    })
  }

  public AddDiscapacidad = async (datosDiscapacidad: ArchivoAdjuntoDiscapacidad) => {
    const { description, file } = datosDiscapacidad

    await this.AddDiscapacidadDescription(description)

    const DISABILITY_CERTIFICATE_TYPE = AdjuntoType[1]

    return this.addArchivoAdjunto(file, DISABILITY_CERTIFICATE_TYPE)
  }

  public AddCvAdjunto = (file: File) => {
    const CV = AdjuntoType[0]
    return this.addArchivoAdjunto(file, CV)
  }

  private addArchivoAdjunto = (file: File, type: string) => {
    const formData = new FormData()
    formData.append('file', file, file.name)

    const request = new Request()
    request.method = Post
    request.path = `/api/curriculum/adjunto/${type}`
    request.body = formData
    request.headers = {
      'Content-Type': 'multipart/form-data',
    }
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo guardar archivo tipo: ${type}`)
    })
  }

  public GetDiscapacidad = (getDatosDiscapacidad: GetArchivoAdjuntoDiscapacidad) => {
    return this.getArchivoAdjunto(getDatosDiscapacidad, 'DOCUMENT')
  }

  public GetCertificadoAdjunto = (getDatosCertificadoAdjunto: GetArchivoCertificadoAdjunto) => {
    return this.getArchivoAdjunto(getDatosCertificadoAdjunto, 'DOCUMENT')
  }

  public GetCvAdjunto = (getDatosCvAdjunto: GetArchivoCvAdjunto) => {
    return this.getArchivoAdjunto(getDatosCvAdjunto, 'DOCUMENT')
  }

  private readonly getArchivoAdjunto = (datosArchivoAdjunto, fileType) => {
    const { fileName, countryCode } = datosArchivoAdjunto
    const request = new Request()
    request.path = `api/curriculum/adjunto/${countryCode}/${fileName}?fileType=${fileType}`
    request.method = Get
    return request
      .call()
      .then(response => {
        if (isEmpty(response)) {
          throw new ErrorApplicant(`Archivo ${fileName} no encontrado`)
        }
        return response
      })
      .catch(error => {
        if (error.response.status === 404) {
          throw new ErrorApplicant(`Archivo ${fileName} no encontrado`)
        }
      })
  }

  public DeleteCvAdjunto = () => {
    const CV = AdjuntoType[0]

    const request = new Request()
    request.method = Delete
    request.path = `/api/curriculum/adjunto/${CV}`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar el cv adjunto`)
    })
  }

  public GetPreferenciaSalarial = () => {
    const request = new Request()
    request.method = Get
    request.path = `/api/curriculum/salario`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo consultar el salario preferencial`)
    })
  }

  public UpdatePreferenciaSalarial = (dato: SalarioType) => {
    const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
    const { origen } = dato
    const request = new Request()
    request.method = Put
    request.path = this.createUrl(`/api/curriculum/salario`, { utmSource, origen })
    request.body = { salario: dato.salario }
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar preferencia salarial`)
    })
  }

  public DeletePreferenciaSalarial = () => {
    const request = new Request()
    request.method = Delete
    request.path = `/api/curriculum/salario`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar la preferencia salarial`)
    })
  }

  public UpdtaeObjetivoLaboral = (datos: ObjetivoLaboralType) => {
    const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
    const { origen } = datos
    const request = new Request()
    request.method = Put
    request.path = this.createUrl(`/api/curriculum/objetivo`, { utmSource, origen })
    request.body = { presentacion: datos.objetivo }
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar el objetivo laboral`)
    })
  }

  public DeleteObjetivoLaboral = () => {
    const request = new Request()
    request.method = Delete
    request.path = `/api/curriculum/objetivo`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar el objetivo laboral`)
    })
  }

  public async GetSkills(query: string): Promise<any> {
    const normQuery = query
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/´/g, '')
    if (!normQuery) {
      return []
    }

    const request = new Request()
    request.path = `/api/curriculum/skills-sugeridas/${normQuery}`
    request.method = Get
    return request.call()
  }

  // Evitamos duplicar el servicio utilizando el bool replaceSkills
  public UpdateSkills = async ({ datos, replaceSkills }: { datos: UpdateSkillsType; replaceSkills: boolean }) => {
    try {
      // Normalizados
      const normalizados = datos.normalizados.map(skill => ({
        tipoConocimiento: 'NORMALIZADO',
        ...skill,
      }))

      // Desnormalizados
      const desnormalizados = datos.desnormalizados.map(skill => ({
        tipoConocimiento: 'DESNORMALIZADO',
        ...skill,
      }))

      // Todos los skills
      const skills = [...normalizados, ...desnormalizados]

      const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
      const { origen } = datos

      const requestNewNormalizados = new Request()
      requestNewNormalizados.method = Put

      requestNewNormalizados.path = this.createUrl(
        `/api/curriculum/conocimientos/${replaceSkills ? 'reemplazar' : 'agregar'}`,
        { utmSource, origen }
      )
      requestNewNormalizados.body = skills
      await requestNewNormalizados.call()
    } catch (e) {
      throw new ErrorApplicant(`Error al actualizar skills`)
    }
  }

  public DeleteSkill = (datos: DeleteSkillType) => {
    const request = new Request()
    request.method = Delete
    request.path = `/api/curriculum/conocimientos/${datos.type}/${datos.id}`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar el skill`)
    })
  }

  public DeleteExperiencia = (experienciaId: string) => {
    const request = new Request()
    request.method = Delete
    request.path = `/api/curriculum/experienciasLaborales/${experienciaId}`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar experiencia laboral`)
    })
  }

  public DeleteReferenciaExperiencia = (referenciaId: string) => {
    const request = new Request()
    request.method = Delete
    request.path = `/api/curriculum/referenciaLaboral/${referenciaId}`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo eliminar la referencia laboral`)
    })
  }

  public UpdatePda = (dato: boolean) => {
    const request = new Request()
    if (dato) {
      request.method = Put
      request.body = { mostrar: dato }
    } else {
      request.method = Delete
    }
    request.path = '/api/curriculum/pda/visibilidad'
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar test de personalidad`)
    })
  }

  public GetLinkReportePda = () => {
    const request = new Request()
    request.method = Post
    request.body = {}
    request.path = '/api/curriculum/pda/download'
    return request
      .call()
      .then(res => res.link)
      .catch(() => {
        throw new ErrorApplicant(`No se pudo obtener el link de descarga de pda`)
      })
  }

  public UpsertExperiencia = (datos: UpdateExperienciaType) => {
    if (datos.id) {
      return this.UpdateExperiencia(datos)
    }
    return this.AddExperiencia(datos)
  }

  public UpdateExperiencia = (datos: UpdateExperienciaType) => {
    const request = new Request()
    request.method = Put
    request.path = `/api/curriculum/experienciasLaborales/${datos.id}`
    request.body = datos
    delete request.body.id
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar la experiencia laboral`)
    })
  }

  public AddExperiencias = (datos: ReplaceExperienciasType) => {
    const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
    const { origen } = datos
    const request = new Request()
    request.method = Put
    request.path = this.createUrl(`/api/curriculum/experienciasLaborales/bulk`, { utmSource, origen })
    request.body = datos.experiencias
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo agregar las experiencias laborales`)
    })
  }

  public AddExperiencia = (datos: UpdateExperienciaType) => {
    // Vemos si tiene UTM Campaign
    const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
    const { origen } = datos
    const request = new Request()
    request.method = Post
    request.path = this.createUrl(`/api/curriculum/experienciasLaborales`, { utmSource, origen })
    request.body = { ...datos }
    delete request.body.id
    delete request.body.origen
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo crear la experiencia laboral`)
    })
  }

  public confirmCandidateAccount = (data: CandidateAccountConfirmation) => {
    const request = new Request()
    request.method = Post
    request.path = `/api/candidates/account-confirmation`
    request.body = data
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo confirmar la cuenta del postulante`)
    })
  }

  public changePassword = async (changePasswordData: ChangePasswordRequestDto): Promise<ChangePasswordSuccessDto> => {
    const request = new Request()
    request.method = Post
    request.path = `/api/ajustes/passwordChange`
    request.body = changePasswordData
    try {
      await request.call()
      return { tokenVencido: false }
    } catch (error: any) {
      if (error.response.status === 400) {
        return { tokenVencido: true }
      }
      throw error
    }
  }

  public markFirstJobSeeker = (data: PrimerEmpleoType) => {
    // Vemos si tiene UTM Campaign
    const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
    const { origen } = data
    const request = new Request()
    request.method = Put
    request.path = this.createUrl(`/api/curriculum/primerEmpleo`, { utmSource, origen })
    request.body = { ...data, utmSource }
    delete request.body.origen
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo marcar que busca su primer empleo`)
    })
  }

  public sinEstudios = (datos: SinEstudioEstudioType) => {
    const utmSource = getCookieData('utm_campaign', document.cookie) ?? undefined
    const { origen } = datos
    const request = new Request()
    request.method = Put
    request.path = this.createUrl(`/api/curriculum/sinEstudios`, { utmSource, origen })
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo marcar que no tiene estudios`)
    })
  }

  public tieneCV = (): boolean => {
    const request = new Request()
    request.method = Get
    request.path = `/api/curriculum/tieneCV`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo consultar si el usuario tiene CV`)
    })
  }

  public actualizarFechaCV = () => {
    const request = new Request()
    request.method = Put
    request.path = `/api/curriculum/actualizaFechaCV`
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar la fecha del CV`)
    })
  }

  public UpdateDocumento = (datos: UpdateDocumentoPayload) => {
    const request = new Request()
    request.method = Patch
    request.path = `/api/candidates/datosPersonales`
    request.body = datos
    return request.call().catch(() => {
      throw new ErrorApplicant(`No se pudo actualizar el documento`)
    })
  }

  public async dataProtectionPolicy(proteccionDatos: boolean) {
    const request = new Request()
    request.path = `/api/politicas`
    request.method = Post
    request.body = {
      proteccionDatos,
    }
    return request.call().catch(error => {
      throw new ErrorApplicant(`Error guardando politica de privacidad, ${error}`)
    })
  }

  public async getVisualizacionesCurriculum() {
    const request = new Request()
    request.path = `/api/candidates/visualizaciones`
    request.method = Get
    return request.call().catch(error => {
      throw new ErrorApplicant(`Error al obtener las visualizaciones, ${error}`)
    })
  }

  private createUrl(path: string, p: { [key: string]: string | null | undefined }): string {
    const params = new URLSearchParams()
    Object.keys(p).forEach(prop => {
      if (p[prop] === null) {
        params.append(prop, 'null')
      } else if (p[prop] !== undefined) {
        params.append(prop, p[prop])
      }
    })
    const textValue = params.toString()
    return textValue ? `${path}?${textValue}` : path
  }
}

const applicant = new Applicant()

export default applicant
