import React, { useEffect, useRef, useState } from 'react'

// Tools
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

// Components
import { Row, Col } from '@navent-jobs/ui-kit/components/grid'
import Text from '@navent-jobs/ui-kit/components/text'
import { SkeletonAlerts } from './components/placeholder/alerts'
import EmptyState from './components/EmptyState'
import ModalRemoveAlertV2 from '../ModalRemove-v2'
import ModalEditAlert from '../ModalEdit-v2'
import DisplayAlert from './components/display-alert'

// Tracking
import { DataLayer } from '../../../../constants/gtm-events-alertasv2'

// Store
import { AlertState, DeleteAlertType, ModalEditAlertType } from '../../../../redux/types/alert-v2'
import { showSnackbar, showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'
import {
  getAlertAction,
  resetEditAlert,
  resetSwitchAlert,
  switchAlertAction,
} from '../../../../redux/actions/alerts-v2'
import { OperationStatus } from '../../../../redux/types/applicant/commons'
import { hideModal } from '../../../../redux/actions/modal'

// Styles
import { Header, SubtitleWrapper } from './mixins'
import { Divider, CustomMainContainer } from '../../mixins'

interface AlertContentProps {
  statusSwitch: OperationStatus
  getAlert: AlertState['getAlert']
  statusEdit: OperationStatus
}

const AlertsContentV2 = ({ getAlert, statusEdit, statusSwitch }: AlertContentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const switchAlertValue = useRef()
  const [editedAletId, setEditedAletId] = useState()
  const handleCloseModal = () => {
    dispatch(hideModal())
  }

  const handleToogle = data => {
    switchAlertValue.current = data.status
    dispatch(
      switchAlertAction({
        id: data.id,
        status: data.status,
      })
    )
  }

  const [alertInfoOnEdit, setAlertInfoOnEdit] = useState<ModalEditAlertType>()
  const [alertInfoOnDelete, setAlertInfoOnDelete] = useState<DeleteAlertType>()

  useEffect(() => {
    if (statusEdit.success) {
      dispatch(hideModal())
      dispatch(resetEditAlert())
      dispatch(showSnackbar(t('account.snackbarMessage.success'), 'icon-light-save'))
      DataLayer.handlerAlertEditedTraking(editedAletId)
    } else if (statusEdit.error) {
      dispatch(showSnackbarError(t('account.snackbarMessage.error')))
    }
  }, [statusEdit])

  useEffect(() => {
    if (statusSwitch.success) {
      dispatch(showSnackbar(t('account.snackbarMessage.success'), 'icon-light-save'))
      switchAlertValue.current && DataLayer.handlerConfigAlertTraking('activarAlerta')
      !switchAlertValue.current && DataLayer.handlerConfigAlertTraking('desactivarAlerta')

      dispatch(resetSwitchAlert())
    } else if (statusSwitch.error) {
      dispatch(showSnackbarError(t('account.snackbarMessage.error')))
    }
  }, [statusSwitch])

  useEffect(() => {
    dispatch(getAlertAction())
  }, [])

  if (getAlert.status.loading) {
    return <SkeletonAlerts />
  }

  return (
    <CustomMainContainer>
      <Row>
        <Col noGlutter>
          <div id="alertas">
            {getAlert.value?.length ? (
              <>
                <Header>
                  <Text size="lg" fontWeight="semibold" as="h2">
                    {t('alertas.panel.title')}
                  </Text>
                  <Divider />
                  <SubtitleWrapper>
                    <Text size="sm" variant="gray60">
                      {t('alertas.panel.subtitle')}
                    </Text>
                  </SubtitleWrapper>
                </Header>
                <DisplayAlert
                  alertArray={getAlert}
                  onEdit={setAlertInfoOnEdit}
                  onDelete={setAlertInfoOnDelete}
                  onToogle={handleToogle}
                />
              </>
            ) : (
              <EmptyState
                title={t('alertas.panel.emptyStateTitle')}
                description={t('alertas.panel.emptyStateDescription')}
              />
            )}
          </div>
        </Col>
      </Row>
      {alertInfoOnEdit && (
        <ModalEditAlert
          alertInfo={alertInfoOnEdit}
          statusEdit={statusEdit}
          trackingTool={setEditedAletId}
          onClose={handleCloseModal}
        />
      )}
      {alertInfoOnDelete && <ModalRemoveAlertV2 idAlert={alertInfoOnDelete} onClose={handleCloseModal} />}
    </CustomMainContainer>
  )
}

const states = ({ alertsV2Store }) => {
  return {
    getAlert: alertsV2Store.getAlert,
    statusEdit: alertsV2Store.editAlert.status,
    statusSwitch: alertsV2Store.switchAlert.status,
  }
}

export default connect(states)(AlertsContentV2)
