import { setCookieData, getCookieData } from '../../utils/cookies'
import { setConfetti } from '../../redux/actions/confetti'

export const showConfetti = async ({ dispatch, cookieKey }) => {
  if (getCookieData(cookieKey, document.cookie) !== 'true') {
    setCookieData(cookieKey, 'true', 86400 * 7) // dura una semana
    setTimeout(() => dispatch(setConfetti()), 1000) // se prende
    setTimeout(() => dispatch(setConfetti()), 7000) // se apaga solo luego de 7 segs
  }
}
