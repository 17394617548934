import { dateUtils } from '@navent-jobs/config'

interface DaysOfPublicationProps {
  fechaPublicacion: string
  fechaHoraPublicacion?: string
  section?: string
  prefijo?: string
}

export const daysOfPublication = ({
  fechaPublicacion,
  fechaHoraPublicacion,
  section,
  prefijo = 'Publicado',
}: DaysOfPublicationProps) => {
  // retorna día en number (1,2,3)
  const cantidadDeDiasAlterno = dateUtils.getTiempoPublicado(fechaPublicacion)
  const hasMyApplications = section === 'mis-postulaciones'

  if (cantidadDeDiasAlterno === 0) {
    if (hasMyApplications) {
      return 'Hoy'
    }
    if (fechaHoraPublicacion) {
      const horasOMinutos = dateUtils.getHoursOrMinutesText(fechaHoraPublicacion)
      return `${prefijo} hace ${horasOMinutos}`
    }
    return `${prefijo} Hoy`
  }

  if (cantidadDeDiasAlterno === 1) {
    return `${hasMyApplications ? 'Ayer' : `${prefijo} ayer`}`
  }

  if (cantidadDeDiasAlterno >= 15) {
    return `${hasMyApplications ? 'Más de 15 días' : `${prefijo} hace más de 15 días`}`
  }

  const textDayOfPublication = hasMyApplications
    ? `Hace ${cantidadDeDiasAlterno} días`
    : `${prefijo} hace ${cantidadDeDiasAlterno} días`

  return textDayOfPublication
}

/**
 * @param fechaModificado en formato DD-MM-YYYY HH:mm:ss
 * @param fechaHoraPublicacion en formato DD-MM-YYYY HH:mm:ss
 * @param fechaPublicacion en formato DD-MM-YYYY
 * @returns wording de cantidad de dias desde la publicacion o modificacion del aviso
 */
export const calcularCantidadDeDias = (
  fechaModificado: string,
  fechaHoraPublicacion: string,
  fechaPublicacion: string
): string => {
  let avisoModificado = false
  if (fechaModificado !== null && fechaModificado !== undefined) {
    // Chequear si la fecha de modificado es 1 dia posterior a la fecha de publicacion
    avisoModificado = dateUtils.daysBetweenISO(fechaHoraPublicacion, fechaModificado) >= 1
  }
  return avisoModificado
    ? daysOfPublication({
        fechaPublicacion: fechaModificado,
        fechaHoraPublicacion: fechaModificado,
        prefijo: 'Actualizado',
      })
    : daysOfPublication({ fechaPublicacion, fechaHoraPublicacion })
}
