import styled from 'styled-components'

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 8px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
  }
`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: ${({ columnDir }) => (columnDir ? 'column' : 'row')};
  align-items: ${({ center }) => center && 'center'};
  gap: 8px;

  @media (min-width: 342px) {
    flex-direction: row;
  }
`

export const CustomText = styled.div`
  * {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-word;
  }
`
