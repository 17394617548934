import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import logoZonajobs from '@navent-jobs/ui-kit/assets/img/logos/zonajobsNaranja.svg'
import logoBumeran from '../../../../assets/images/logos/bumeran.svg'
import { DataIcon, DataInfo } from '../../mixins'
import { LastInfoCol, InterPageLogo, LastDataContainer, DisabilityContainer } from './mixins'
import { planConfig } from '../../constants/planConfig'
import Text from '@navent-jobs/ui-kit/components/text'
import ApplicationStatus from '../ApplicationStatus'

const Component = ({
  aptoDiscapacitado,
  tipoAviso,
  mobileFormat,
  isDesktop,
  index,
  read,
  estadoPostulacionRealizada,
  estadoPostulacionEspera,
  saveJobPostingEnabled,
  handleSavePosting,
  savePosting,
  notInterestedJobPostingEnabled,
  setVisibleNotInterestedModal,
}) => {
  const isMobileFormat = !isDesktop || mobileFormat
  const tipoAvisoCross = planConfig[tipoAviso].isAvisoZonaJobs || planConfig[tipoAviso].isAvisoBumeran
  const { colors } = useContext<{ colors }>(ThemeContext)

  const HighlightedType = () => {
    if (!planConfig[tipoAviso].showHighlight) return null

    return (
      <>
        {tipoAvisoCross ? (
          <InterPageLogo
            src={planConfig[tipoAviso].isAvisoZonaJobs ? logoZonajobs : logoBumeran}
            alt="logo Zonajobs"
            aptoDiscapacitado={aptoDiscapacitado}
            mobileFormat={mobileFormat}
          />
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <LastDataContainer mobileFormat={mobileFormat}>
      <LastInfoCol
        mobileFormat={mobileFormat}
        aptoDiscapacitado={aptoDiscapacitado}
        crossSite={planConfig[tipoAviso].isAvisoZonaJobs || planConfig[tipoAviso].isAvisoBumeran}
      >
        {!tipoAvisoCross && (
          <ApplicationStatus
            index={index}
            tipoAviso={tipoAvisoCross}
            read={read}
            estadoPostulacionRealizada={estadoPostulacionRealizada}
            estadoPostulacionEspera={estadoPostulacionEspera}
            saveJobPostingEnabled={saveJobPostingEnabled}
            handleSavePosting={handleSavePosting}
            savePosting={savePosting}
            isDesktop={isDesktop}
            mobileFormat={mobileFormat}
            notInterestedJobPostingEnabled={notInterestedJobPostingEnabled}
            setVisibleNotInterestedModal={setVisibleNotInterestedModal}
          />
        )}

        {!isMobileFormat && aptoDiscapacitado && (
          <DisabilityContainer
            mobileFormat={mobileFormat}
            crossSite={planConfig[tipoAviso].isAvisoZonaJobs || planConfig[tipoAviso].isAvisoBumeran}
          >
            <DataIcon name="icon-light-discapacity" size="20" color={colors.grey84} />
            <DataInfo>
              <Text size="sm" variant="gray84" as="h3">
                Apto discapacidad
              </Text>
            </DataInfo>
          </DisabilityContainer>
        )}

        <HighlightedType />
      </LastInfoCol>
    </LastDataContainer>
  )
}

export default Component
